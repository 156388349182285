import React, { useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { fetchAccounts, fetchAuxAccount } from '../../Reducers/accounts';
import AccountsTable from './AccountsTable';
import CreateAccount from './CreateAccount';
import UpdateAccounts from './UpdateAccounts';
import {useDispatch, useSelector} from 'react-redux';
import DisableButton from './DisableButton';
import EnableButton from './EnableButton';
import ErrorBlock from '../../Components/ErrorBlock';
import useTable from '../../Utils/useTable';
import './styles.scss';
import useStorageState from "../../Utils/useStorageState";
import {Input} from "antd";

const b = b_.lock('Accounts');

const { Search } = Input;

function Accounts() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    login,
    onSearch
  } = useTable(state => state.accounts.list, fetchAccounts);
  const { payload: countries } = useSelector((state) => state.accounts.aux);
  const dispatch = useDispatch();
  useEffect(() => {
      !countries && dispatch(fetchAuxAccount());
  }, [countries]);

  const [selectedRow, setSelectedRow] = useState([]);

  const [ accountsChosen, setAccountsChosen ] = useStorageState('accountsChosen', {nameGroups:[], groups: {}});

  const addAccountChosen = (id, group) => {
      setAccountsChosen((e) =>{
          const objAcc = {
              nameGroups: [...e.nameGroups],
              groups: {...e.groups}
          }

          if(!objAcc.groups[group] ) {
              objAcc.groups[group] = []
          }

          let typeGroupNew = objAcc.nameGroups = objAcc.nameGroups.map(e => e.value ? e.value : e)

          typeGroupNew = typeGroupNew.indexOf(group) === -1
              ? [...typeGroupNew, group]
              : [...typeGroupNew.filter(s => s !== group), group]

          const accountsIdNew = objAcc.groups[group].indexOf(id) === -1
              ?  [...objAcc.groups[group], id]
              : [...objAcc.groups[group].filter(s => s !== id), id]

          objAcc.nameGroups = typeGroupNew.map(es => ({text: es, value: es}))
          objAcc.groups[group] = accountsIdNew

          return  objAcc
      })
  }

  const removeAccountChosen = (id, group) => {
      setAccountsChosen((e) =>{
          const objAcc = {
              nameGroups: [...e.nameGroups],
              groups: {...e.groups}
          }

          const typeGroupRemove = [...objAcc.nameGroups.filter(gr => gr.value !== group)]
          const accountsIdRemove = []

          objAcc.nameGroups = typeGroupRemove
          objAcc.groups[group] = accountsIdRemove

          return  objAcc
      })
  }

  const removeAccountFromGroup = (id, groups) => {
        setAccountsChosen((e) =>{
            const objAcc = {
                nameGroups: [...e.nameGroups],
                groups: {...e.groups}
            }
            let massGr = objAcc.nameGroups.map(e => e.value ? e.value : e)
             massGr = massGr.filter((elem) => groups.indexOf(elem) === -1)

            if(massGr.length) {
                objAcc.nameGroups.filter(es => {
                    if( massGr.indexOf(es.value) !== -1) {
                        return objAcc.groups[es.value] = objAcc.groups[es.value].filter(e => e !== id)
                    }
                    if( massGr.indexOf(es.value) === -1) {
                        return objAcc.groups[es.value] = [...objAcc.groups[es.value].filter(e => e !== id), id]
                    }
                })
            }
            if(!massGr.length) {
                objAcc.nameGroups.forEach(e => objAcc.groups[e.value].indexOf(id) === -1 ?
                    objAcc.groups[e.value] = [...objAcc.groups[e.value], id]
                    :[...objAcc.groups[e.value].filter(s => s !== id), id] )
            }
            return  objAcc
        })
    }

  return <div className={b()}>
    <Title className="mb">
      Accounts
    </Title>
    <div className={b('accountBlock')}>
      <CreateAccount className="mr" afterSuccess={fetch} />
      <UpdateAccounts className="mr" afterSuccess={fetch} selected={selectedRow} />
      <DisableButton className="mr" selected={selectedRow} afterSuccess={fetch} />
      <EnableButton selected={selectedRow} afterSuccess={fetch} />
    </div>
    <div>
        <Search defaultValue={login} placeholder="input search text" className="mb" size="large" onSearch={onSearch}/>
    </div>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <AccountsTable selected={selectedRow}
                   countries={countries?.countries}
                   setSelected={setSelectedRow}
                   afterUpdate={fetch}
                   list={list}
                   pagination={pagination}
                   onChange={onChange}
                   onFilter={onFilter}
                   isLoading={isLoading}
                   filters={filters}
                   sorter={sort}
                   accountsLocal={accountsChosen}
                   addAccountChosen={addAccountChosen}
                   removeAccountChosen={removeAccountChosen}
                   removeAccountFromGroup={removeAccountFromGroup}
    />
  </div>;
}

export default Accounts;
