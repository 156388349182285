import React from 'react';
import { Row, Col } from 'antd';
import { JSONTree } from 'react-json-tree';
import theme from '../../../Utils/jsonTheme';
import PrintDate from '../../Prints/PrintDate';
import PrintDateOnlyTime from '../../Prints/PrintDateOnlyTime';

function Expandable({ className, record }) {
  const { trace, changes, resultDetails } = record;

  return <Row className={className} gutter={16}>
    <Col span={8}>
      <b className="block mb">Trace</b>
      <ul>
        {trace.map(({ name, time }) => {
          const fontColor = name.includes('Processing interrupted') ? 'red' : ''

          return <li style={{color: fontColor}}>{name} - <PrintDate withTime date={time} /></li>
        })}
      </ul>
    </Col>
    <Col span={8}>
      <b className="block mb">Changes</b>
      <JSONTree hideRoot theme={theme} data={changes} shouldExpandNode={() => true} />
    </Col>
    <Col span={8}>
      <b className="block mb">Result Details</b>
      <JSONTree hideRoot theme={theme} data={resultDetails} shouldExpandNode={() => true} />
    </Col>
  </Row>
}

export default Expandable;
