import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import Logout from '../Logout';
import './styles.scss';
import {Link} from 'react-router-dom';
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {links} from "../../Boot/Routes";
import {history} from "../../Boot/createStore";
import {Button} from "@material-ui/core";
import fec from 'front-end-common';

const AntHeader = Layout.Header;

const { SubMenu } = Menu;
const { Sider } = Layout;

const IconType = ({state}) => {
    return (
        <Button
            style={{backgroundColor: "#1976d2",  width:50, paddingLeft: "23px" }}
            color="primary"
            variant="contained">
            {state ? <MenuUnfoldOutlined style={{fontSize: 20}} /> : <MenuFoldOutlined style={{fontSize: 20}}/>}
        </Button>
    )
}

function Header() {
    const [ icon, setIcon] = useState(false)
    const path = links.filter((link) => history.location.pathname === link.href)
    const platforms = fec.utils.platformsData;
    return (
        <AntHeader className="Header">
            <Sider width="63px" className="Menu">           
                <Menu
                    mode="vertical"
                    forceSubMenuRender={false}
                    defaultSelectedKeys={(path.length && [path[0].href] )|| ''}
                    defaultOpenKeys={(path.length && [path[0].href] )|| ''}
                    onOpenChange={() => setIcon(!icon)}
                    triggerSubMenuAction={"click"}
                    style={{
                        borderRight: 0,
                    }}
                >
                    <SubMenu
                        key="menu"
                        style={{ fontSize: 15 }}
                        title={<IconType state={icon} />}
                    >
                        { links.map((link) => (
                            <Menu.Item key={link.href}  >
                                <Link to={link.href} >
                                    {link.icon}
                                    <span>{link.title}</span>
                                </Link>
                            </Menu.Item>
                        )) }
                    </SubMenu>
                </Menu>
            </Sider>
            <Menu
                defaultSelectedKeys={['resell']}
                mode="horizontal"
                className="Nav"
            >
              {platforms?.map((link) => (
                <Menu.Item key={link.key}>
                  <a href={link.link}>{link.name}</a>
                </Menu.Item>
                ))}
                {/* <Menu.Item key="resell">
                    <a href="https://resell.hoteos.net">Resell</a>
                </Menu.Item>
                <Menu.Item key="blizzard">
                    <a href="https://blizzard.hoteos.net">Blizzard</a>
                </Menu.Item>
                <Menu.Item key="amz">
                    <a href="https://amz.hoteos.net">Amazon</a>
                </Menu.Item>
                <Menu.Item key="paa">
                    <a href="https://paa.hoteos.net">XBox</a>
                </Menu.Item>
                <Menu.Item key="paxful">
                    <a href="https://paxful-dashboard.hoteos.net/">Paxful</a>
                </Menu.Item> */}
            </Menu>

            <div className="Logout">
                <Logout />
            </div>
        </AntHeader>
    )
}

export default React.memo(Header);
