import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { cancelJobsAccount, fetchAccount, fetchJobsAccount } from '../../Reducers/account';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccountTable from './AccountTable';
import JobsTable from '../../Components/JobsTable';
import { message } from 'antd';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import { fetchAuxAccount } from '../../Reducers/accounts';
import JobFilters from '../../Components/JobFilters';
import useTable from '../../Utils/useTable';
import './styles.scss';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

const b = b_.lock('Account');

function Account() {
  const dispatch = useDispatch();
  const params = useParams();

  const fetchJobs = useCallback((data) => fetchJobsAccount({ ...data, account_id: params.id }), []);
  const { fetch, ...props } = useTable(
    state => state.account.jobs,
    fetchJobs,
  );

  const [selectedRow, setSelectedRow] = useState([]);
  const cancelLoading = useSelector(state => state.account.cancelJobs.isLoading, shallowEqual);

  const {
    payload,
    isLoading,
  } = useSelector(state => state.account.account, shallowEqual);

  useEffect(() => {
    dispatch(fetchAccount(params.id));
    dispatch(fetchAuxAccount());
  }, [dispatch, params.id]);

  const cancelJobs = useCallback(() => {
    dispatch(cancelJobsAccount(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  const afterUpdate = useCallback(() => {
    dispatch(fetchAccount(params.id));
    fetch();
  }, [fetch, dispatch, params]);

  return <div>
    <Title className="mb">Account-details - {(payload && payload.login) || params.id}</Title>
    <AccountTable isLoading={isLoading}
                  data={(payload && [payload]) || null}
                  afterUpdate={afterUpdate}
                  className="mb"
    />
    <Title className={`mb ${b('title')}`}>
      Jobs
      <Button
          onClick={cancelJobs}
          disabled={!selectedRow.length}
          startIcon={(props.isLoading || cancelLoading ) ? <LoadingOutlined /> :<StopOutlined />}
          color="secondary"
          variant="contained"
          className="mb-small"
      >
        Cancel Jobs
      </Button>
    </Title>
    <JobFilters filters={props.filters} onFilter={props.onFilter} className="mb" />
    <JobsTable {...props} nameLocalStore="JobsResell"  selected={selectedRow} setSelected={setSelectedRow} fetch={fetch} />
  </div>;
}

export default Account;
