import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import ValidateCode from './ValidateCode';
import InvalidateCode from './InvalidateCode';
import CancelCode from './CancelCode';
import EditableField from '../../../Components/EditableField';
import {Input, message} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {updateCodeCodeStock, updateCommentCodeStock} from '../../../Reducers/codeStock';
import './styles.scss';
import useStorageState from "../../../Utils/useStorageState";
import TableSettings from "../../Accounts/AccountsTable/TableSettings";
import ResizeTable from "../../../Components/ResizebleTitle";
import {fetchAuxAccount} from "../../../Reducers/accounts";
import DeleteCode from "./DeleteCode";

const b = b_.lock('CodeStockTable');

function CodeStockTable({list, isLoading, className, pagination, onChange, sort, selected, setSelected, fetch, filters}) {
  const dispatch = useDispatch();
  const loadings = useSelector(state => ({
    updateCode: state.codeStock.updateCode.isLoading,
    updateComment: state.codeStock.updateComment.isLoading,
  }));

  const { payload: aux , isLoading: updateCountries} = useSelector((state) => state.accounts.aux);

  useEffect(() => {
      if(!aux) dispatch(fetchAuxAccount());
  }, [dispatch]);

  const updateCode = useCallback((id, code) => {
    dispatch(updateCodeCodeStock({codeIds: [id], code}))
      .then(() => {
        message.success('Comment has been updated!');
      })
      .catch(() => {
        message.error('Can\'t update code!');
      });
  }, [dispatch]);

  const updateComment = useCallback((id, code) => {
      dispatch(updateCodeCodeStock({codeIds: [id], code}))
          // dispatch(updateCommentCodeStock({codeIds: [id], comment:code}))
      .then(() => {
        message.success('Code has been updated!');
      })
      .catch(() => {
        message.error('Can\'t update code!');
      });
  }, [dispatch]);

    const [Checkboxs, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState('accountsResell', {
        checkedList: Checkboxs,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    const GetColumns = (sorter, group) => {
        return [
            {
                dataIndex: "id",
                title: "ID",
                align: "center",
                width: 90,
                sorter,
                sortOrder: sorter.field === 'id' ? sorter.order : null,
                render: (e) => <a href={`codes/${e}`}>{e}</a>,
            },
            {
                dataIndex: "platform",
                title: "Platform",
                align: "center",
                width: 145,
                sorter,
                sortOrder: sorter.field === 'platform' ? sorter.order : null,
            },
            {
                dataIndex: "code",
                title: "Code",
                align: "center",
                width: 270,
                render: (code, rec) => (
                    <EditableField handleSave={(newCode) => updateCode(rec.id, newCode)}
                                   title="Update Code"
                                   withTitle
                                   isLoading={loadings.updateCode || isLoading}
                                   iconClassName={b('edit')}
                                   titlePopover="Edit Code"
                                   initialValue={code}
                                   changeBlock={({onChange, ...props}) => (
                                       <Input {...props}
                                              size="small"
                                              onChange={e => onChange(e.target.value)}/>
                                   )}>
                        {code}
                    </EditableField>
                )
            },
            {
                dataIndex: "type",
                title: "Type",
                align: "center",
                width: 126,
            },
            {
                dataIndex: "reward",
                title: "Reward",
                align: "center",
                width: 104,
            },
            {
                dataIndex: "group",
                title: "Group",
                align: "center",
                width: 167,
                sorter,
                ...(group ? {
                    filters: group.map(e => ({value: e, text:e})),
                    filterMultiple: false,
                    filteredValue: filters['group'] || null,
                } : {}),
                sortOrder: sorter.field === 'group' ? sorter.order : null,
            },
            {
                dataIndex: "status",
                title: "Status",
                align: "center",
                width: 193,
                sorter,
                sortOrder: sorter.field === 'status' ? sorter.order : null
            },
            {
                dataIndex: "comment",
                title: "Comment",
                align: "center",
                width: 157,
                sorter,
                sortOrder: sorter.field === 'comment' ? sorter.order : null,
                render: (comment, rec) => (
                    <EditableField handleSave={(newComment) => updateComment(rec.id, newComment)}
                                   title="Update Comment"
                                   withTitle
                                   isLoading={loadings.updateComment || isLoading}
                                   iconClassName={b('edit')}
                                   titlePopover="Edit Comment"
                                   initialValue={comment}
                                   changeBlock={({onChange, ...props}) => (
                                       <Input {...props}
                                              size="small"
                                              onChange={e => onChange(e.target.value)}/>
                                   )}>
                        {comment}
                    </EditableField>
                )
            },
            {
                dataIndex: "id",
                title: "Actions",
                align: "center",
                width: 105,
                render: (id, rec) => <div>
                    <ValidateCode afterValidate={fetch} platform={rec.platform} code={rec.code} status={rec.status}
                                  id={rec.id}/>
                    {
                        rec.status === 'Valid'
                        ? <CancelCode afterCancel={fetch} platform={rec.platform} code={rec.code} status={rec.status}
                                    id={rec.id}/>
                        : <InvalidateCode afterInvalidate={fetch} platform={rec.platform} code={rec.code} status={rec.status}
                        id={rec.id}/>
                    }
                    <DeleteCode afterDelete={fetch} code={rec} status={rec.status}/>
                </div>
            }
        ]
    }

    const [columns, setColumns] = useState(GetColumns(sort));

    useEffect(() => {
        let allCheckList = []

        if(!Checkboxs.length) {
            GetColumns(sort).forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
            })
        }

    }, [AllCheckbox])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList:GetColumns(sort).map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    const groupsSet = Array.from(new Set(aux?.groups))

    useEffect(() => {
        setColumns(GetColumns(sort, groupsSet))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sort,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        aux?.countries.length,
        filters,
        loadings.updateCode,
        loadings.updateComment,
        updateCountries,
        isLoading,
    ])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);

  return (
    <ResizeTable bordered
           className={`${className} ${b()}`}
           pagination={pagination}
           onChange={onChange}
           dataSource={list}
           scroll={{ y: "calc(100vh - 125px)", x: "max-content" }}
           size="middle"
           loading={isLoading}
           rowKey="id"
           rowClassName={() => b('lock')}
           rowSelection={{
               selectedRowKeys: selected.map(v => v.id),
               onChange: (selectedRowKeys, selectedRows) => {
                   setSelected(selectedRows)
               },
           }}
        //    scroll={{x: 400}}
           columns={columnsFilter}
           title={() => <TableSettings
               onChangeCheckAll={onChangeCheckAll}
               allChecks={AllCheckbox}
               Checkboxs={AllCheckbox.checkedList}
               TableColumn={() =>GetColumns(sort)}
               setCheckbox={onChangeCheckList}
           />}
    >
    </ResizeTable>
  );
}

export default CodeStockTable;
