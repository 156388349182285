import React, {useCallback, useState} from 'react';
import { Modal } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import frc from 'front-end-common';
import {Button} from "@material-ui/core";
import ImageViewer from "react-simple-image-viewer";

const useBoolean = frc.hooks.useBoolean;

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function ImageViews({ className, images }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
  };

  return <>
    <Button
        color={"primary"}
        variant={"contained"}
        className={className}
        style={{backgroundColor: "#1976d2"}}
        size="small"
        onClick={open}>
        show
    </Button>
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} height={"90vh"} centered  >
      <Carousel showArrows={true}>
        {images.map((img, key) => (
            // <div onClick={() => openImageViewer(key) } key={key}>
            <div onClick={() => openImageViewer(key) } key={key}>

            <img
                src={BACKEND_URL + img}
                
                style={{ maxHeight: "70vh", pointerEvents: "unset", objectFit: "contain" }}
                alt="screenshot"
                />
             </div>
        ))}
      </Carousel>
        {/* {isViewerOpen && (
            <ImageViewer
                src={images.map(e => BACKEND_URL + e)}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.6)"
                }}
            />
        )} */}
    </Modal>
  </>;
}

export default ImageViews;
