import React, {useEffect, useState} from 'react';
import {LogoutOutlined} from '@ant-design/icons';
import TokenService from '../../Services/TokenService';
import {isLocal} from "../../Utils/domainUtils";


const {
    REACT_APP_AUTH_REDIRECT_URL: REDIRECT_URL,
    REACT_APP_AUTH_REDIRECT_URL_LOCAL: REDIRECT_URL_LOCAL,
    REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
    REACT_APP_AUTH_CLIENT_ID_LOCAL: CLIENT_ID_LOCAL,
    REACT_APP_AUTH_URL: AUTH_URL,
    REACT_APP_AUTH_URL_LOCAL: AUTH_URL_LOCAL,
    REACT_APP_AUTH_RESPONSE_TYPE: RESPONSE_TYPE,
    REACT_APP_AUTH_SCOPE: SCOPE,
    REACT_APP_AUTH_SCOPE_LOCAL: SCOPE_LOCAL,
} = process.env;

const url = isLocal() ? `${AUTH_URL_LOCAL}/logout?client_id=${CLIENT_ID_LOCAL}&response_type=${RESPONSE_TYPE}&scope=${SCOPE_LOCAL}&redirect_uri=${REDIRECT_URL_LOCAL}`
    : `${AUTH_URL}/logout?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&redirect_uri=${REDIRECT_URL}`;

function Logout({className = ''}) {
    const [sha, setSha] = useState(null);

    useEffect(() => {
            TokenService.generateAndSaveSha256().then(data => {
                setSha(data);
            });
        },
        [],
    );

    return (<a href={sha && (url + `&code_challenge_method=S256&code_challenge=${sha}`)}
    >
        <LogoutOutlined className={className}/>
    </a>);
}

export default React.memo(Logout);
