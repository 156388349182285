import ApiService from './ApiService';

export default {
  fetchDrivers() {
    return ApiService.get('/drivers');
  },
  removeUserDataDir(id) {
    return ApiService.get('/driver/remove_user_data_dir', {account_id: id });
  },
  defaultUserDataDir(id) {
    return ApiService.get('/driver/default_user_data_dir', {account_id: id });
  },
  vpnLookup(provider, query) {
    return ApiService.get('/vpn_names', { provider, query });
  },
  updateVpn(data) {
    return ApiService.put('/vpn', data)
  },
  update(data) {
    return ApiService.post('/driver', data)
  },
   create(data) {
    return ApiService.put('/driver', data)
  },
}
