import React  from 'react';
import { Popconfirm, Button } from 'antd';

function ResetProfile({ accountId, removeUserData}) {
    return <>
        <Popconfirm title={"do you really want to reset the user profile chrome"} onConfirm={() =>removeUserData(accountId)}>
            <Button type={"link"} style={{padding: 0, height: 21}}>Reset profile</Button>
        </Popconfirm>
    </>;
};

export default ResetProfile;
