import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import PrintDriverStatus from '../../../Components/Prints/PrintDriverStatus';
import ErrorBlock from '../../../Components/ErrorBlock';
import UpdateDriver from '../UpdateDriver';
import UpdateVpn from '../UpdateVpn';
import './styles.scss';
import Expandable from './Expandable';
import TableSettings from "../../Accounts/AccountsTable/TableSettings";
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizebleTitle";

const b = b_.lock('DriverTable');

function DriverTable({ isLoading, error, payload, className, fetch }) {
    const [Checkboxs, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState('DriverTableResell', {
        checkedList: Checkboxs,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    const GetColumns = () => {
        return [
            {
                dataIndex: "id",
                title: "ID"
            },
            {
                dataIndex: "group",
                title: "Group"
            },
            {
                dataIndex: "driver",
                title: "Driver"
            },
            {
                dataIndex: "host",
                title: "Host" },
            {
                dataIndex: "port",
                title: "Port"
            },
            {
                dataIndex: "port2",
                title: "Port 2"
            },
            {
                dataIndex: "userDataDirPath",
                title: "User Data Dir"
            },
            {
                dataIndex: "type",
                title: "Type"
            },
            {
                dataIndex: "uses",
                title: "Uses"
            },
            {
                dataIndex: "status",
                title: "Status",
                render: (status) => <PrintDriverStatus status={status} />
            },
            {
                dataIndex: "vpn",
                title: "VPN"
            },
            {
                dataIndex: "vpnProvider",
                title: "Provider"
            },
            {
                dataIndex: "vpnStatus",
                title: "VPN Status",
                render: (vpnStatus, record) => {if(record.updating === true) {return 'Updating...'} else {return vpnStatus}}
            },
            {
                dataIndex: "id",
                title: "Actions",
                align: "center",
                render:(id, record) => (
                    <div className={b('actions')}>
                        <UpdateDriver record={record} afterUpdate={fetch} id={id} />
                        <UpdateVpn afterUpdate={fetch} id={id} />
                    </div>
                )
            }
        ]
    }

    const [columns, setColumns] = useState(GetColumns());

    useEffect(() => {
        let allCheckList = []

        if(!Checkboxs.length) {
            GetColumns().forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
            })
        }

    }, [AllCheckbox])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList:GetColumns().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    useEffect(() => {
        setColumns(GetColumns())
    }, [])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);

  return (
    <>
      {error && <ErrorBlock message={error.message} description={error.description} />}
      <ResizeTable bordered
             className={className}
             loading={isLoading}
             pagination={false}
             scroll={{ x: 'max-content' }}
             dataSource={payload}
             rowKey="id"
             expandable={{
               expandedRowRender: record => <Expandable record={record} />,
             }}
             columns={columnsFilter}
             title={() => <TableSettings
                 onChangeCheckAll={onChangeCheckAll}
                 allChecks={AllCheckbox}
                 Checkboxs={AllCheckbox.checkedList}
                 TableColumn={() =>GetColumns()}
                 setCheckbox={onChangeCheckList}
             />}
      >
      </ResizeTable>
    </>
  );
}

export default DriverTable;
