import React  from 'react';
import { Popconfirm, Button } from 'antd';

function DefaultProfile({ accountId, defaultUserData}) {
    return <>
        <Popconfirm title={"do you really want to use default chrome profile"} onConfirm={() =>defaultUserData(accountId)}>
            <Button type={"link"} style={{padding: 0, height: 21}}>Default profile</Button>
        </Popconfirm>
    </>;
};

export default DefaultProfile;
