import { createAction, createReducer } from '@reduxjs/toolkit';
import CodeStockService from '../Services/CodeStockService';
import frc from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchCodeStockList = createAction('codeStock/list', (params) => ({
  payload: CodeStockService.fetchList(params),
}));

export const fetchCodeGroupBalance = createAction('codeStock/groupBalance', () => ({
  payload: CodeStockService.fetchGroupBalance(),
}));

export const updateCodeCodeStock = createAction('codeStock/updateCode', (data) => ({
  payload: CodeStockService.updateCode(data),
}));

export const updateCommentCodeStock = createAction('codeStock/updateComment', (data) => ({
  payload: CodeStockService.updateComment(data),
}));

export const updateCodeStock = createAction('codeStock/update', (data) => ({
  payload: CodeStockService.update(data),
}));

export const validateCodeStock = createAction('codeStock/validate', (data) => ({
  payload: CodeStockService.validateCodes(data),
}));

const initState = {
  groupBalance: getDefaultState(),
  list: getDefaultState(),
  updateCode: getDefaultState(),
  updateComment: getDefaultState(),
  update: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchCodeGroupBalance, 'groupBalance'),
  ...getDefaultHandler(fetchCodeStockList, 'list'),
  ...getDefaultHandler(updateCodeCodeStock, 'updateCode'),
  ...getDefaultHandler(updateCommentCodeStock, 'updateComment'),
  ...getDefaultHandler(updateCodeStock, 'update'),
});
