import React, { useEffect, useState, useMemo } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { fetchState } from '../../Reducers/state';
import StateTable from './StateTable';
import InvalidAccountsTable from './InvalidAccountsTable';
import {useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import useTable from '../../Utils/useTable';
import './styles.scss';
import useStorageState from "../../Utils/useStorageState";
import {Input} from "antd";

const b = b_.lock('State');

const { Search } = Input;

function State() {
  const {
    info,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    login,
    onSearch
  } = useTable(state => state.state.list, fetchState);

  const lastErrorAccounts = useSelector((state) => state.state.list.payload);

  const { invalidAccounts } = useMemo(() => ({
    invalidAccounts: (lastErrorAccounts && lastErrorAccounts.lastErrorAccounts) || [],
  }), [lastErrorAccounts]);

  const [ accountsChosen ] = useStorageState('accountsChosen', {nameGroups:[], groups: {}});


  return <div className={b()}>
    <Title className="mb">Invalid accounts</Title>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <InvalidAccountsTable
        afterUpdate={fetch}
        list={invalidAccounts}
        pagination={pagination}
        onChange={onChange}
        onFilter={onFilter}
        isLoading={isLoading}
        filters={filters}
        sorter={sort}
        accountsLocal={accountsChosen}
    />
    <Title className="mb" level={2}>Accounts state</Title>
    <StateTable
        afterUpdate={fetch}
        list={info}
        pagination={pagination}
        onChange={onChange}
        onFilter={onFilter}
        isLoading={isLoading}
        filters={filters}
        sorter={sort}
        accountsLocal={accountsChosen}
    />
  </div>;
}

export default State;
