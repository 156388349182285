import React, { useEffect } from 'react';
import b_ from 'b_';
import './styles.scss';
import Table from 'antd/es/table/Table';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchCodeGroupBalance } from '../../../Reducers/codeStock';

const b = b_.lock('CodeGroupBalanceTable');


function CodeGroupBalanceTable({ className }) {
  const { payload, isLoading } = useSelector(state => state.codeStock.groupBalance, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCodeGroupBalance());
  }, [dispatch]);

  return (<Table bordered
                className={b('table')}
                 pagination={false}
                 dataSource={payload && payload.list}
                 loading={isLoading}
                 size="small"
                 scroll={{ x: "max-content" }}
  >
    <Table.Column align={"center"} dataIndex="platform" title="Platform" width={100}/>
    <Table.Column align={"center"} dataIndex="group" title="Group" width={100}/>
    <Table.Column align={"center"} dataIndex="codeName" title="Code Name" width={120}/>
    <Table.Column align={"center"} dataIndex="stock" title="Stock" width={70}/>
    <Table.Column align={"center"} dataIndex="stockSoldNotValidated" title="Stock (sold-not)" width={140}/>
    <Table.Column align={"center"} dataIndex="avgReward" title="Average Reward" width={140}/>
  </Table>);
}

export default CodeGroupBalanceTable;
