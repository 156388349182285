import React, { useEffect, useState } from 'react';
import b_ from 'b_';
import { Card } from 'antd';
import AuthLayout from '../../Components/Layouts/AuthLayout';
import './styles.scss';
import AmazonOutlined from '@ant-design/icons/lib/icons/AmazonOutlined';
import TokenService from '../../Services/TokenService';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";
import {isLocal} from "../../Utils/domainUtils";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const {
  REACT_APP_AUTH_REDIRECT_URL: REDIRECT_URL,
  REACT_APP_AUTH_REDIRECT_URL_LOCAL: REDIRECT_URL_LOCAL,
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_CLIENT_ID_LOCAL: CLIENT_ID_LOCAL,
  REACT_APP_AUTH_URL: AUTH_URL,
  REACT_APP_AUTH_URL_LOCAL: AUTH_URL_LOCAL,
  REACT_APP_AUTH_RESPONSE_TYPE: RESPONSE_TYPE,
  REACT_APP_AUTH_SCOPE: SCOPE,
  REACT_APP_AUTH_SCOPE_LOCAL: SCOPE_LOCAL,
} = process.env;

const url = isLocal() ? `${AUTH_URL_LOCAL}/authorize?client_id=${CLIENT_ID_LOCAL}&response_type=${RESPONSE_TYPE}&scope=${SCOPE_LOCAL}&redirect_uri=${REDIRECT_URL_LOCAL}`
    : `${AUTH_URL}/authorize?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&redirect_uri=${REDIRECT_URL}`;

const b = b_.lock('SignIn');

function SignIn() {
  const [sha, setSha] = useState(null);
  useEffect(() => {
      TokenService.generateAndSaveSha256().then(data => {
        setSha(data);
      });
    },
    [],
  );

  return <AuthLayout className={b()}>
    <Card className={b('card')} title="Sign In">
      <a className={b('link')}
         href={sha && (url + `&code_challenge_method=S256&code_challenge=${sha}`)}
      >
        <Button color="primary"
                style={{backgroundColor: "#1976d2" }}
                variant="contained"
                size="large"
                startIcon={sha === null ? <LoadingOutlined/> : <AmazonOutlined />}
        >
          Sign In with Amazon Cognito
        </Button>
      </a>
    </Card>
  </AuthLayout>;
}

export default SignIn;
