import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import accounts from './accounts';
import account from './account';
import state from './state';
import checkCodesJobs from './checkCodesJobs'
import otherJobs from './otherJobs'
import codeStock from './codeStock'
import config from './config';
import driver from './driver';
import codes from './codes';

export default combineReducers({
  auth,
  accounts,
  checkCodesJobs,
  otherJobs,
  account,
  state,
  codeStock,
  config,
  driver,
  codes,
});

