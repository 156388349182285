import { createAction, createReducer } from '@reduxjs/toolkit';
import CodesService from '../Services/CodesService';
import frc from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;
export const fetchCodesId = createAction('codes/id', (params) => ({
    payload:params.id && CodesService.code(params),
}));

const initState = {
    codes: getDefaultState(),
};

export default createReducer(initState, {
    ...getDefaultHandler(fetchCodesId, 'codes'),
});
