import React, {useCallback, useState} from 'react';
import { Drawer, Form, Input} from 'antd';
import { useDispatch } from 'react-redux';
import useBoolean from "front-end-common/src/hooks/useBoolean";
import {Button, Link} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

function AccountsChosen({ accountId,  addAccountChosen, accounts, removeAccountChosen, className }) {

    const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
    const dispatch = useDispatch();
    const [removeGroup, setRemoveGroup] = useState("");

    const handleSuccess = useCallback(({ titleGroup, ...p }) => {
        addAccountChosen(accountId, titleGroup)
        close();
    }, [dispatch, accountId, close]);

    const handleRemove = useCallback(() => {
        removeAccountChosen(accountId, removeGroup)
        close();
    }, [dispatch, accountId, close, removeGroup]);

    return <>
        <Link
            className={className}
            style={{ textDecoration: 'none' }}
            color="primary" size="small"
            onClick={open}>
            Change eject</Link>
        <Drawer onClose={close}
                visible={isOpen}
                title="Change group"
                placement="right"
                width={600}
        >
            <Form layout="vertical" onChange={(e) => setRemoveGroup(e.target.value)} onFinish={handleSuccess}>
                <Form.Item
                    rules={[
                        { required: true, message: 'Please input change.' },
                    ]}
                    name="titleGroup"
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        style={{ backgroundColor: "#1976d2"}}
                        // startIcon={isLoading && <LoadingOutlined />}
                    >
                        add account to group
                    </Button>
                </Form.Item>
            </Form>
            <Button
                onClick={handleRemove}
                variant="contained"
                color="secondary"
                style={{
                    textDecoration: 'none',
                    position: 'absolute',
                    left: 255,
                    top: 135,
                }}
                type="submit">
                delete Group
            </Button>
        </Drawer>
    </>;
};

export default AccountsChosen;
