import React, {useCallback, useEffect, useState} from 'react';
import {Drawer, Form, Select} from "antd";
import useBoolean from "front-end-common/src/hooks/useBoolean";
import {useDispatch} from "react-redux";
import {Button, Link} from "@material-ui/core";



function AccountGroups({ accountId, accounts, removeAccountFromGroup }) {

    const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
    const dispatch = useDispatch();

    const handleSuccess = useCallback(({ titleGroup }) => {
        removeAccountFromGroup(accountId, titleGroup)
        close();
    }, [dispatch, accountId, close]);


    const getGroupsAcc = (acc) => {
        return acc.nameGroups.filter(e => {
            if(acc.groups[e.value].indexOf(accountId) !== -1) {
                return e.value
            }
            return
        } ).map(e => e.value)
    }

    const [objKey, setObjKey] = useState(getGroupsAcc(accounts))
    useEffect( () => {
        setObjKey (getGroupsAcc(accounts))
    }, [accounts, close])

    return <>
        <Link
                style={{ textDecoration: 'none' }}
                color="primary"
                size="small"
                onClick={open}>Manage groups</Link>
        <Drawer onClose={close}
                visible={isOpen}
                title="Change account groups"
                placement="right"
                width={600}
        >
            <Form layout="vertical" onFinish={handleSuccess}>
                <Form.Item
                    rules={[
                        { required: false},
                    ]}
                    name="titleGroup"
                    key = {accounts}
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        defaultValue={objKey}
                        key = {objKey}
                    >
                        {accounts.nameGroups.map(e =>  <Select.Option
                            value={e.value}
                            key={e.value}>{e.value}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        style={{ backgroundColor: "#1976d2"}}
                    >
                        change account groups
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    </>;
};

export default AccountGroups;
