import React, {useState} from 'react';
import b_ from 'b_';
import ResizeTable from "../../../Components/ResizebleTitle";
import './styles.scss'
import Expanded from "../../Accounts/AccountsTable/Expanded";
import {Link} from "react-router-dom";

const b = b_.lock('CodesTable');

function CodesTable ({ list, isLoading, selected, setSelected}) {
    const GetColumns = () => {
        return [
            {
                dataIndex: "id",
                title: "ID",
                sorter: (row, rowTo) => row.id - rowTo.id,
                render: (id) => <Link to={`/check-codes-jobs?filters[jobId][]=${id}`}>{id}</Link>
            },
            {
                dataIndex: "created",
                title: "Date Created",
                sorter: (row, rowTo) => row.created - rowTo.created,
                render: (field) => {
                    const date = new Date(field);
                    return <div>{date.toLocaleString('en-GB')}</div>
                }
            },
            {
                dataIndex: "updated",
                title: "date Updated",
                sorter: (row, rowTo) => row.updated - rowTo.updated,
                render: (field) => {
                    const date = new Date(field);
                    return <div>{date.toLocaleString('en-GB')}</div>
                }
            },
            {
                dataIndex: "workerGroup",
                title: "Group",
            },
            {
                dataIndex: "status",
                title: "Status",
            },
            {
                dataIndex: "stage",
                title: "Stage",
            },
            {
                dataIndex: "resultCode",
                title: "Result Code"
            },
            {
                dataIndex: "workerName",
                title: "Worker Name",
                render: (field, states) => {
                  return <a href={"/account/" + states.workerId}>{field}</a>;
                }
            },
        ]
    }

    const [columns, setColumns] = useState(GetColumns())

    return <>
        <ResizeTable bordered
            scroll={{ x: 'max-content' }}
            loading={isLoading}
            onChange={() => setColumns(GetColumns())}
            dataSource={list}
            columns={columns}
            rowClassName={() => b('lock')}
            rowKey="id"
            rowSelection={{
                selectedRowKeys: selected,
                onChange: setSelected,
            }}
            expandable={{
                expandedRowRender: record =>  <Expanded record={record} />,
                rowExpandable: record => !!record
            }}
        />
    </>;
}

export default CodesTable;
