import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import { JSONTree } from 'react-json-tree';
import PrintJobStatus from '../Prints/PrintJobStatus';
import pathToFileName from '../../Utils/pathToName';
import PrintDate from '../Prints/PrintDate';
import theme from '../../Utils/jsonTheme';
import Expandable from './ Expandable';
import PrintFromNow from '../Prints/PrintFromNow';
import ImageViews from '../ImageViews';
import './styles.scss';
import useStorageState from "../../Utils/useStorageState";
import TableSettings from "../../Pages/Accounts/AccountsTable/TableSettings";
import ResizeTable from "../ResizebleTitle";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const b = b_.lock('JobTable');

// TODO  удалить при правильной настройке nginx
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function JobsTable({
                       list,
                       pagination,
                       onChange,
                       isLoading,
                       selected,
                       setSelected,
                       nameLocalStore,
                       filters,
                       sort
}) {

    const [Checkboxs, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState( nameLocalStore, {
        checkedList: Checkboxs,
        indeterminate: false,
        checkAll: true,
    });
    const { resultCodes } = useSelector(
      (state) => state.checkCodesJobs.resultCodes,
      // resultCodesCheckCodesJobs
    );

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    const GetColumns = () => {
        return [
            {
                dataIndex: "id",
                title: "ID",
                align: "center",
                width: "85px",
                render: (id, {phase}) => 
                 <Link to={`/check-codes-jobs?filters[jobId][]=${id}`}>{id}</Link>
                // render: (id, {phase}) => nameLocalStore === "JobsResell" ||  nameLocalStore === "checkCodesJobsResell" ?
                //     <Link to={`/${phase === "Check" ? "check-codes-jobs" 
                //         : "other-jobs"}?filters[jobId][]=${id}`}>{id}</Link>
                //     : id
            },
            {
                dataIndex: "platform",
                title: "Platform",
                align: "center",
                width: "90px",
            },
            {
                dataIndex: "createTs",
                title: "Created",
                align: "center",
                width: "250px",
                sorter: sort,
                sortOrder: sort.field === 'createTs' ? sort.order : null,
                render: (create) => <PrintDate withTime date={create} />
            },
            {
                dataIndex: "updateTs",
                title: "Updated",
                align: "center",
                width: "93px",
                render: (update, rec) => <PrintFromNow date={rec.createTs} start={update} />
            },
            {
                dataIndex: "accountName",
                title: "Account Name",
                align: "center",
                width: "233px",
                render: (name, {accountId}) => accountId
                && nameLocalStore !== "JobsResell" ? <Link to={`/account/${accountId}`}>{name}</Link> : name
            },
            {
                dataIndex: "status",
                title: "Status",
                align: "center",
                width: "85px",
                render: status => <PrintJobStatus jobStatus={status} />
            },
            {
                dataIndex: "phase",
                title: "Phase",
                align: "center",
                width: "74px",
            },
            {
                title: "Action",
                dataIndex: "action",
                align: "center",
                width: "440px",
                render: json => <JSONTree hideRoot theme={theme} data={json} />
            },
            {
                dataIndex: "resultCode",
                title: "Result Code",
                align: "center",
                width: "400px",
                sorter: sort,
                sortOrder: sort.field === 'resultCode' ? sort.order : null,
                ...(resultCodes ? {
                    filters: resultCodes.map(item => ({value: item, text: item})),
                    filterMultiple: true,
                    filteredValue: filters['resultCode'] || null,
                } : {}),
                render: (resultCode, rec) =>
                  <>
                      {resultCode === "OK" ? resultCode : <JSONTree hideRoot theme={theme} data={ {[resultCode]: rec.resultDetails?.[0] }} />}
                  </>
            },
            {
                dataIndex: "screens",
                title: "Screens",
                align: "center",
                width: "97px",
                render: images => (<ImageViews images={images} />)
            },
            {
                dataIndex: "pages",
                title: "Pages",
                align: "center",
                width: "130px",
                render: pages => (<div>
                    {pages.map(page =>
                        <a className="mb-small block" href={BACKEND_URL + page} rel="noreferrer noopener" target="_blank">
                            {pathToFileName(page)}
                        </a>)}
                </div>),
            },
        ]
    }

    const [columns, setColumns] = useState(GetColumns());

    useEffect(() => {
        let allCheckList = []
        if(list.length && !Checkboxs.length) {
            GetColumns().forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
            })
        }

    }, [AllCheckbox])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList:GetColumns().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    useEffect(() => {
        setColumns(GetColumns())
    }, [filters, isLoading])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);

  return <ResizeTable bordered
                className={b()}
                scroll={{ y: "calc(100vh - 125px)", x: "max-content" }}
                size="middle"
                dataSource={list}
                rowKey="id"
                onChange={onChange}
                pagination={pagination}
                loading={isLoading}
                rowSelection={{
                  selectedRowKeys: selected,
                  onChange: setSelected,
                }}
                columns={columnsFilter}
                title={() => <TableSettings
                    onChangeCheckAll={onChangeCheckAll}
                    allChecks={AllCheckbox}
                    Checkboxs={AllCheckbox.checkedList}
                    TableColumn={() =>GetColumns()}
                    setCheckbox={onChangeCheckList}
                />}
                expandable={{
                  expandedRowRender: record => <Expandable record={record} />,
                }}
  >
  </ResizeTable>;
}

export default JobsTable;
