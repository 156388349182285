import React, { useCallback } from 'react';
import { Popconfirm, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { noArgAction } from '../../../Reducers/accounts';
import {CheckOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button} from "@material-ui/core";

function EnableButton({ className, selected, afterSuccess }) {
  const { isLoading } = useSelector(state => state.accounts.noArgAction, shallowEqual);
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    dispatch(noArgAction({ action: 'enable', accountIds: selected }))
      .then(() => {
      afterSuccess();
      message.success('Accounts has been enabled!');
    })
      .catch(() => {
        message.error('Can\'t enabled accounts!');
      });
  }, [dispatch, selected, afterSuccess]);

  return (<Popconfirm title={`Are you sure enabled ${selected.length} accounts?`}
                      onConfirm={submit}
                      okText="Yes"
                      cancelText="No"
  >
    <Button
        className={className}
        startIcon={isLoading ? <LoadingOutlined/> : <CheckOutlined />}
        color="primary"
        style={selected.length ? {backgroundColor: "#4caf50"} : {}}
        variant="contained"
        disabled={!selected.length}>
      Enable {selected.length} accounts
    </Button>
  </Popconfirm>);
}

export default EnableButton;
