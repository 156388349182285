import ApiService from './ApiService';

export default {
  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/accounts', updatedParams);
  },
  changeProxy(params) {
    const { id, proxy } = params;
    return ApiService.post('/account/update', { accountIds: [id], proxy });
  },
  changeEnabledJobs(params) {
    const { id, enabledJobs } = params;
    return ApiService.post('/account/update', { accountIds: [id], enabledJobs });
  },
  changeBalLimit(params) {
    const { id, balLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], balLimit });
  },
  changePurchLimit(params) {
    const { id, purchLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], purchLimit });
  },
  changeLogin(params) {
    const { id, login } = params;
    return ApiService.post('/account/update', { accountIds: [id], login });
  },
  changeCountry(params) {
    const { id, country } = params;
    return ApiService.post('/account/update', { accountIds: [id], country });
  },
  changeDriver(params) {
    const { id, driver } = params;
    return ApiService.post('/account/update', { accountIds: [id], driver });
  },
  changeComment(params) {
    const { id, comment } = params;
    return ApiService.post('/account/update', { accountIds: [id], comment });
  },
  createAccount(data) {
    return ApiService.put('/account', data);
  },
  updateAccounts(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.post('/account/update', updatedData);
  },
  changePassword(data) {
    return ApiService.post('/account/set_password', data);
  },
  changeToken(data) {
    return ApiService.post('/account/update', data);
  },
  changeStatus(data) {
    const { id, action } = data;
    return ApiService.post('/job/no_arg', { action, accountIds: [id] });
  },
  fetchAux() {
    return ApiService.get('/accounts/aux');
  },
  fetchAccount(id) {
    return ApiService.get('/account', { account_id: id });
  },
  getJobs(data) {
    return ApiService.get('/jobs', data);
  },
  noArgAction(data) {
    return ApiService.post('/job/no_arg', data);
  },
};
