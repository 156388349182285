import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import './styles.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { fetchCardsConfig } from '../../../Reducers/config';
import useStorageState from "../../../Utils/useStorageState";
import TableSettings from "../../Accounts/AccountsTable/TableSettings";
import ResizeTable from "../../../Components/ResizebleTitle";

const b = b_.lock('CardsTable');

function CardsTable({ className = '' }) {
  const { error, isLoading, payload } = useSelector(state => state.config.cards, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCardsConfig());
  }, [dispatch]);

    const [Checkboxs, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState('cardConfigurationResell', {
        checkedList: Checkboxs,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    const GetColumns = () => {
        return [
            {
                dataIndex: "id",
                title: "id",
                width: 50,
            },
            {
                dataIndex: "platform",
                title: "Platform",
                width: 200,
            },
            {
                dataIndex: "name",
                title: "Name",
                width: 350,
            },
            {
                dataIndex: "group",
                title: "Group",
                width: 100,
            },
            {
                dataIndex: "rewardCurr",
                title: "Currency",
                width: 100,
            },
            {
                dataIndex: "basePrice",
                title: "Base Price",
                width: 100,
            }
        ]
    }

    const [columns, setColumns] = useState(GetColumns());

    useEffect(() => {
        let allCheckList = []

        if(!Checkboxs.length) {
            GetColumns().forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AllCheckbox])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList:GetColumns().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    useEffect(() => {
        setColumns(GetColumns())
    }, [])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);

  return (
    <>
      {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
      <ResizeTable bordered
             className={`${b()} ${className}`}
             loading={isLoading}
             pagination={false}
             scroll={{ y: "calc(100vh - 125px)", x: "max-content" }}
             size="middle"
             dataSource={payload}
             rowKey="id"
             columns={columnsFilter}
             title={() => <TableSettings
                 onChangeCheckAll={onChangeCheckAll}
                 allChecks={AllCheckbox}
                 Checkboxs={AllCheckbox.checkedList}
                 TableColumn={() =>GetColumns()}
                 setCheckbox={onChangeCheckList}
             />}
      >
      </ResizeTable>
    </>
  );
}

export default CardsTable;
