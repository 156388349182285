import React, { useCallback, useMemo } from 'react';
import b_ from 'b_';
import { Drawer, Form, Input, message } from 'antd';
import CountrySelector from '../Selectors/CountrySelector';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { createAccount } from '../../../Reducers/accounts';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import PlatformSelector from '../../../Components/PlatformSelector';
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import {Button} from "@material-ui/core";
import "./styles.scss"

const useBoolean = frc.hooks.useBoolean;

const b = b_.lock('CreateAccount');

function CreateAccount({ className, afterSuccess }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const handleSuccess = useCallback((data) => {
    dispatch(createAccount(data)).then(() => {
      close();
      afterSuccess();
      message.success('Account has been added!');
    });
  }, [close, afterSuccess, dispatch]);

  const error = useSelector(state => state.accounts.create.error, shallowEqual);

  return (
    <>
      <Button
              className={className}
              onClick={open}
              startIcon={<PlusOutlined/>}
              color="primary"
              variant="contained"
              style={{ backgroundColor: "#1976d2"}}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Account" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            rules={[
              { required: true, message: 'Please select platform.' },
            ]}
            label="Platform:"
            name="platform"
          >
            <PlatformSelector className={b("platform")} />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input account login.' },
            ]}
            label="Login"
            name="login"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input password.' },
            ]}
            label="Password"
            name="password"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Token"
            name="authToken"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select country.' },
            ]}
            label="Country:"
            name="country"
          >
            <CountrySelector />
          </Form.Item>
          <Form.Item
            label="Proxy:"
            name="proxy"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Comment:"
            name="comment"
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
            <Button
                startIcon={isLoading && <LoadingOutlined/>}
                color="primary"
                variant="contained"
                size="small"
                style={{ backgroundColor: "#1976d2"}}
                type="submit"
            >
                Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateAccount;
