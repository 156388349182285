import moment from 'moment';

const FORMATS = {
  DATE: 'DD/MM/YYYY',
  DATE_WITH_TIME: 'DD/MM/YYYY HH:mm:ss',
  TIME_WITHOUT_DATE: 'HH:mm:ss',
};

export function serverDateToDateWithTime(date) {
  if (!date) {
    return null;
  }

  return moment(date).format(FORMATS.DATE_WITH_TIME);
}

export function serverDateToDate(date) {
  if (!date) {
    return null;
  }

  return moment(date).format(FORMATS.DATE);
}

export function serverDateToTimeWithoutDate(date) {
  if (!date) {
    return null;
  }

  return moment(date).format(FORMATS.TIME_WITHOUT_DATE);
}

export function serverDateToDateWithTimezone(timestamp) {
  const date = new Date(timestamp);

  const timezoneOffsetInMinutes = date.getTimezoneOffset();

  const absOffset = Math.abs(timezoneOffsetInMinutes);
  const hours = Math.floor(absOffset / 60);
  const minutes = absOffset % 60;

  const sign = timezoneOffsetInMinutes <= 0 ? "+" : "-";

  const pad = (num) => String(num).padStart(2, '0');

  const formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())} ${sign}${pad(hours)}:${pad(minutes)} UTC`;

  return formattedDate;
}

export function unixToMoment(unix) {
  return (+unix && moment(+unix)) || null;
}

export function momentToUnix(v) {
  return (v && v.valueOf()) || null;
}

export function unixFromNow(unix, startUnix = null) {
  if (!unix) return null;

  return timeSince(moment(startUnix && +startUnix).unix() - moment(+unix).unix());
}

function timeSince(sec) {
  const sec_num = parseInt(sec, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;
  // const arr = ['h', 'm', 's'];

  return `${hours !== 0 ? (hours < 10 ? '0' + hours + 'h ' : hours + 'h ') : ''}${minutes !== 0 ? (minutes < 10 ? '0' + minutes + 'm ' : minutes + 'm ') : ''}${seconds !== 0 ? (seconds < 10 ? '0' + seconds + 's ' : seconds + 's ') : ''}`
  // return [hours, minutes, seconds]
  //   .map(v => v < 10 ? '0' + v : v)
  //   .reduce((acc, cur, i) => `${acc} ${cur}${arr[i]} `, '');
}
