import React, {useEffect, useRef} from 'react';
import b_ from 'b_';
import {Drawer, message, Upload} from 'antd';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import {useDispatch} from 'react-redux';
import {zalandoCheckCodes} from '../../../Reducers/checkCodesJobs';
import './styles.scss';
import {Button} from "@material-ui/core";
import frc from "front-end-common";

const b = b_.lock('UploadZalandoCodes');

const useBoolean = frc.hooks.useBoolean;

function UploadZalandoCodes({ className, afterSuccess }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { current: form } = useRef(new FormData());
  const dispatch = useDispatch();

  useEffect(() => {
    form.set('group', 'R-Zalando:EUR');
  }, []);

  const handleSubmit = () => {
    if (!form.has('file')) {
      return message.error('Please fill the form!');
    }

    dispatch(zalandoCheckCodes(form))
      .then(() => {
      message.success('Codes have been uploaded!');
      afterSuccess();
      close();
    })
  };

  const beforeUpload = (file) => {
    form.delete('file');
    form.append('file', file);
    return false;
  };

  const onRemove = () => {
    form.delete('file');
  };

  return (<>
    <Button
        color="primary"
        variant="contained"
        onClick={open}
        className={className}
        style={{ backgroundColor: "#1976d2", marginRight: "10px"}}
        startIcon={<UploadOutlined />}>
      Upload Zalando
    </Button>
    <Drawer visible={isOpen} title="Upload Zalando" width={600} closable placement="right" onClose={close}>
      <div className={b('line')}>
        <Upload accept=".txt"
                onRemove={onRemove}
                beforeUpload={beforeUpload}
        >
          <Button
              variant="contained"
              startIcon={<UploadOutlined />}
          >Select File</Button>
        </Upload>
      </div>
      <div className={b('line')}>
        <Button
            style={{ backgroundColor: "#1976d2"}}
            color="primary"
            variant="contained"
            onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Drawer>
  </>);
}

export default UploadZalandoCodes;
