import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {Input, message, Popover } from 'antd';
import CodeStockService from '../../../../Services/CodeStockService';
import frc from 'front-end-common';
import {Button, Link} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

const { useRequest, useBoolean } = frc.hooks;

function DeleteCode({ className, status, code, afterDelete }) {
  const [confirmValue, setConfirmValue] = useState('');
  const { value: showPopover, setFalse: closePopover, setValue: handleVisibleChange } = useBoolean(false);

  const onChangeConfirm = useCallback((e) => setConfirmValue(e.target.value), [setConfirmValue]);

  useEffect(() => {
    setConfirmValue('');
  }, [showPopover]);

  const { state: { isLoading }, fetch } = useRequest(
    () => CodeStockService.deleteCode(code)
      .then(() => {
        message.success('Delete has been started');
        closePopover();
        afterDelete();
      })
      .catch(() => message.error('Something went wrong!')),
  );
  const disabled = useMemo(() => confirmValue.toLocaleLowerCase() !== 'delete', [confirmValue]);

  return (
    <div className={className}>
      {(status === 'Invalid')  &&
      <Popover
        content={
          <div>
            <Input className="mb-small" value={confirmValue} onChange={onChangeConfirm} />
            <Button color="primary"
                    variant="contained"
                    onClick={fetch}
                    startIcon={isLoading && <LoadingOutlined/>}
                    style={!disabled ?{ backgroundColor: "#1976d2"} : {}}
                    disabled={disabled}
                    size="small"
            >
              Confirm
            </Button>
          </div>
        }
        onVisibleChange={handleVisibleChange}
        visible={showPopover}
        trigger="click"
        title="For confirm action please type 'delete':"
      >
        <Link
            color="primary"
            style={{ textDecoration: 'none'}}
        >
          delete
        </Link>
      </Popover>}
    </div>
  );
}

export default DeleteCode;
