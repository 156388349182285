import ApiService from './ApiService';

export default {
  fetchGroupBalance() {
    return ApiService.get('/codes_grp_bal', { offset: 0, limit: 100 });
  },
  fetchList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = Array.isArray(filters[key]) ? filters[key].join(',') : filters[key];
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/codes', updatedParams);
  },
  validateCode(platform, code) {
    return ApiService.post('/code/validate', [{ platform, code }]);
  },
  validateCodes(codes) {
    return ApiService.post('/code/validate', codes);
  },
  cancelCode(platform, code) {
    return ApiService.post('/code/cancel', [{ platform, code }]);
  },
  deleteCode(code) {
    return ApiService.delete('/codes/' + code.id);
  },
  invalidateCode(platform, code) {
    return ApiService.post('/code/invalidate', [{ platform, code }]);
  },
  updateCode(data) {
    return ApiService.post('/code/update', data);
  },
  updateComment(data) {
    return ApiService.post('/code/update', data);
  },
  update(data) {
    return ApiService.post('/code/update', data);
  },
};
