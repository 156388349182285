import { createAction, createReducer } from '@reduxjs/toolkit';
import OtherJobService from '../Services/OtherJobService';
import frc from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;
export const fetchOtherJobs = createAction('otherJobs/list', (params) => ({
  payload: OtherJobService.getList(params),
}));

export const cancelOtherJobs = createAction('otherJobs/cancel', (ids) => ({
  payload: OtherJobService.cancel(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchOtherJobs, 'list'),
  ...getDefaultHandler(cancelOtherJobs, 'cancel'),
});
