import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Col, Input, Row } from 'antd';
import CodeStatusSelector from '../../../Components/CodeStatusSelector';
import PlatformSelector from '../../../Components/PlatformSelector';
import './styles.scss';
import {Button} from "@material-ui/core";

const b = b_.lock('CodeStockFilters');

function CodeStockFilters({ className = '', filters, onFilter }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  return (
    <Row gutter={16} justify={'space-between'} className={`${b()} ${className}`}>
      <Col style={{width: 200}} order={1} >
        <span className={b('label')}>Status</span>
        <CodeStatusSelector value={curFilters.status ? curFilters.status[0] : null}
               className={b('item')}
               onChange={e => handleChangeFilter(e, 'status')}
        />
      </Col>
      <Col style={{width: 200}} order={2}>
        <span className={b('label')}>Platform</span>
        <PlatformSelector onChange={(e) => handleChangeFilter(e, 'platform')}
                          value={curFilters.platform ? curFilters.platform[0] : null}
                          className={b('item')}
        />
      </Col>
      <Col order={3}>
        <span className={b('label')}>Code</span>
        <Input value={curFilters.code ? curFilters.code[0] : null}
                            className={b('item')}
                            onChange={e => handleChangeFilter(e.target.value, 'code')}
        />
      </Col>
      <Col order={4}>
        <span className={b('label')}>Type</span>
        <Input value={curFilters.type ? curFilters.type[0] : null}
                            className={b('item')}
                            onChange={e => handleChangeFilter(e.target.value, 'type')}
        />
      </Col>
      <Col order={10}>
        <span className={b('label')} style={{marginBottom: 4}}/>
        <Button
            color="primary"
            variant="contained"
            style={{ width: '150px', backgroundColor: "#1976d2"}}
            onClick={() => onFilter(curFilters)}
        >Filter</Button>
      </Col>
    </Row>
  );
}

export default CodeStockFilters;
