import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function ResultCodeSelector({ className, value, onChange, defaultValue, placeholder, disabled, size }) {
  return (
    <Select defaultValue={defaultValue}
            placeholder={placeholder}
            className={className}
            size={size}
            value={value}
            onChange={onChange}
            disabled={disabled}
            allowClear
    >
      <Select.Option value="OK">OK</Select.Option>
      <Select.Option value="LOGIN_ERROR">LOGIN_ERROR</Select.Option>
      <Select.Option value="ALREADY_REDEEMED">ALREADY_REDEEMED</Select.Option>
      <Select.Option value="DRV_TIMEOUT">DRV_TIMEOUT</Select.Option>
      <Select.Option value="CODE_INVALID">CODE_INVALID</Select.Option>
      <Select.Option value="SCRIPT_ERROR">SCRIPT_ERROR</Select.Option>
      <Select.Option value="WRONG_REGION">WRONG_REGION</Select.Option>
      <Select.Option value="CODE_IS_LOCKED">CODE_IS_LOCKED</Select.Option>
      <Select.Option value="ACCOUNT_TEMPORARILY_LOCKED">ACCOUNT_TEMPORARILY_LOCKED</Select.Option>
      <Select.Option value="CAPTCHA_ERROR">CAPTCHA_ERROR</Select.Option>
    </Select>
  );
}

ResultCodeSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

ResultCodeSelector.defaultProps = {
  disabled: false,
};

export default ResultCodeSelector;
