import React from 'react';
import { Col, Row } from 'antd';
import b_ from 'b_';
import { JSONTree } from 'react-json-tree';
import theme from '../../../../Utils/jsonTheme';
import './styles.scss';

const b = b_.lock('Expanded');

function Expanded({ className, record }) {
  const { resultDetails, state, extState } = record;

  return <div className={`${b()} ${className}`}>
    <Row>
      <Col width="100px">
        {resultDetails && resultDetails.length !== 0
          ? <div className={b('container')}>
            <b className="block mb">Details</b>
            <JSONTree hideRoot theme={theme} data={resultDetails || record} shouldExpandNode={() => true} />
          </div>
          : <div className={b('container_row')}>
            <i className="block mb">Details </i>
            <JSONTree hideRoot theme={theme} data="no resultDetails data" shouldExpandNode={() => true} />
          </div>
        }
      </Col>
      <Col width="100px">
        {state
          ? <div className={b('container')}>
            <b className="block mb">State</b>
            <JSONTree hideRoot theme={theme} data={state} shouldExpandNode={() => true} />
          </div>
          : <div className={b('container_row')}>
            <i className="block mb">State </i>
            <JSONTree hideRoot theme={theme} data="no state data" shouldExpandNode={() => true} />
          </div>
        }
      </Col>
      <Col width="100px">
        {extState
          ? <div className={b('container')}>
            <b className="block mb">ExtState</b>
            <JSONTree hideRoot theme={theme} data={extState} shouldExpandNode={() => true} />
          </div>

          : <div className={b('container_row')}>
            <i className="block mb">ExtState </i>
            <JSONTree hideRoot theme={theme} data="no extState data" shouldExpandNode={() => true} />
          </div>
        }
      </Col>
    </Row>
  </div>;
}

export default Expanded;
