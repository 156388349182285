import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { message } from 'antd';
import Title from 'antd/lib/typography/Title';
import { cancelOtherJobs, fetchOtherJobs } from '../../Reducers/otherJobs';
import JobsTable from '../../Components/JobsTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import './styles.scss';
import JobFilters from '../../Components/JobFilters';
import useTable from '../../Utils/useTable';
import {LoadingOutlined} from "@ant-design/icons";
import {Button} from "@material-ui/core";

const b = b_.lock('OtherJobs');

function OtherJobs() {
  const { fetch, onFilter, ...props } = useTable(state => state.otherJobs.list, fetchOtherJobs);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.otherJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelOtherJobs(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  return <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Other Jobs
    </Title>
    <div className={`mb ${b('buttons')}`}>
      <Button onClick={cancelJobs}
              disabled={!selectedRow.length}
              startIcon={(props.isLoading || cancelLoading) ? <LoadingOutlined/> : <StopOutlined />}
              color={"secondary"}
              variant="contained"
              size="large"
      >
        Cancel Jobs
      </Button>
    </div>
    <JobFilters withCodeFilter={false} filters={props.filters} onFilter={onFilter} className="mb" />
    <JobsTable {...props} nameLocalStore="OtherJobsResell"  selected={selectedRow} setSelected={setSelectedRow} />
  </div>;
}

export default OtherJobs;
