import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Col, DatePicker, Input, Row } from 'antd';
import JobStatusPicker from '../JobStatusPicker';
import { momentToUnix, unixToMoment } from '../../Utils/dates';
import PlatformSelector from '../PlatformSelector';
import ResultCodeSelector from '../ResultCodeSelector';
import './styles.scss';
import {Button} from "@material-ui/core";

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('JobFilters');

function JobFilters({ className, filters, onFilter, withCodeFilter = true, withResultCodeFilter }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  return <Row gutter={16} justify={'space-between'} className={`${className} ${b()}`}>
        <Col order={1} >
            <span className={b('label')}>JobId</span>
            <Input value={curFilters.jobId ? curFilters.jobId[0] : null}
                   className={b('item')}
                   onChange={e => handleChangeFilter(e.target.value, 'jobId')}
            />
        </Col>
        <Col order={2} style={{width:140}}>
            <span className={b('label')}>Platform</span>
            <PlatformSelector value={curFilters.platform ? curFilters.platform[0] : null}
                              className={b('item')}
                              onChange={(value) => handleChangeFilter(value, 'platform')}
            />
        </Col>
        <Col order={3} style={{width:100}} >
            <span className={b('label')}>Status</span>
            <JobStatusPicker className={b('item')}
                             value={curFilters.status ? curFilters.status[0] : null}
                             onChange={(v) => handleChangeFilter(v, 'status') }
            />
        </Col>
        {withCodeFilter && <Col order={4}>
            <span className={b('label')}>Code</span>
            <Input className={b('item')}
                   value={curFilters.code ? curFilters.code[0] : null}
                   onChange={e => handleChangeFilter(e.target.value, 'code')}
            />
        </Col>}
        {withResultCodeFilter && <Col order={5} style={{width:200}}>
            <span className={b('label')}>Result Code</span>
            <ResultCodeSelector
                            value={curFilters.resultCode ? curFilters.resultCode[0] : null}
                            className={b('item')}
                            onChange={(value) => handleChangeFilter(value, 'resultCode')}
            />
        </Col>}
        <Col order={6}>
            <span className={b('label')}>Dates</span>
            <RangePicker showTime
                         value={curFilters.Dates ? curFilters.Dates.map(unixToMoment) : [null, null]}
                         className={b('item')}
                         onChange={dates => handleChangeFilter(dates ? dates.map(momentToUnix) : null, 'Dates')}
            />
        </Col>
        <Col gutter={2} order={7} >
            <span className={b('label')}  style={{marginBottom: 0}}/>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                style={{ backgroundColor: "#1976d2"}}
                onClick={() => onFilter(curFilters)}
            >Filter</Button>
        </Col>
  </Row>;
}

export default JobFilters;
