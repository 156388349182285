import React from 'react';
import PropTypes from 'prop-types';
import {Popover, Tag} from 'antd';

const STATE_TO_COLOR = {
  Enabled: 'green',
  Disabled: 'gray',
  Invalid: 'red',
  TempInvalid: 'yellow',
};

function PrintAccountState({ className, status, handleSave }) {
    const statuses = ["Disable", "Enable"]
    return <Popover content={`Change Status on the ${statuses.filter(e => !status.includes(e))[0]}`}>
        <Tag style={{ cursor: "pointer"}}
             onClick={() => handleSave(...statuses.filter(e => !status.includes(e) ))}
             className={[className, status]} color={STATE_TO_COLOR[status]}>{status}</Tag>
    </Popover>
}

PrintAccountState.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(STATE_TO_COLOR)).isRequired,
};

PrintAccountState.defaultProps = {
  className: '',
};

export default PrintAccountState;
