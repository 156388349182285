import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function StatusSelector({ className, value, onChange, defaultValue, placeholder, disabled, size }) {
  return (
    <Select defaultValue={defaultValue}
            placeholder={placeholder}
            className={className}
            size={size}
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            disabled={disabled}
            allowClear
    >
      <Select.Option value="Enabled">Enabled</Select.Option>
      <Select.Option value="Disabled">Disabled</Select.Option>
    </Select>
  );
}

StatusSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

StatusSelector.defaultProps = {
  disabled: false,
};

export default StatusSelector;
