import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function CodeStatusSelector({ className, value, onChange, defaultValue, placeholder, disabled }) {

  return (
    <Select defaultValue={defaultValue}
            placeholder={placeholder}
            className={className}
            allowClear
            value={value}
            onChange={onChange}
            disabled={disabled}
    >
      <Select.Option value="Valid">Valid</Select.Option>
      <Select.Option value="Done">Done</Select.Option>
      <Select.Option value="Sold">Sold</Select.Option>
      <Select.Option value="Sold Not Activated">Sold Not Activated</Select.Option>
      <Select.Option value="Invalid">Invalid</Select.Option>
      <Select.Option value="Used(1)">Used(1)</Select.Option>
      <Select.Option value="Used(2)">Used(2)</Select.Option>
      <Select.Option value="Cancel Initialized">Cancel Initialized</Select.Option>
      <Select.Option value="Revoke Initialized">Revoke Initialized</Select.Option>
      <Select.Option value="Revoked">Revoked</Select.Option>
      <Select.Option value="Cancelled">Cancelled</Select.Option>
    </Select>
  );
}

CodeStatusSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
};

export default CodeStatusSelector;
