import { createAction, createReducer } from '@reduxjs/toolkit';
import StateService from '../Services/StateService';
import frc from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchState = createAction('state/list', (params) => ({
  payload: StateService.getList(params),
}));

const initState = {
  list: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchState, 'list'),
});
