import React from 'react';
import b_ from 'b_';
import Title from 'antd/es/typography/Title';
import CardsTable from './CardsTable';
import ConfigList from './ConfigList';
import './styles.scss';

const b = b_.lock('Config');

function Config() {

  return (
    <div className={b()}>
      <Title level={1} className="mb">
        Config Page
      </Title>
      <Title level={2} className={b('cartTitle')}>
        Card Configuration
      </Title>
      <CardsTable className="mb" />
      <Title level={2} className="mb">
        Default Configs
      </Title>
      <ConfigList />
    </div>
  );
}

export default Config;
