import React, { useState } from 'react';
import Title from 'antd/lib/typography/Title';
import CodeGroupBalanceTable from './CodeGroupBalanceTable';
import { fetchCodeStockList } from '../../Reducers/codeStock';
import CodeStockTable from './CodeStockTable';
import CodeStockFilters from './CodeStockFilters';
import UpdateCode from './UpdateCode';
import ValidateCode from './ValidateCode';
import useTable from '../../Utils/useTable';
import './styles.scss';
import b_ from "b_";

const b = b_.lock('CodesStock');

function CodesStock() {
  const tableProps = useTable(state => state.codeStock.list, fetchCodeStockList);
  const [selected, setSelected] = useState([]);

  return <div>
    <Title className="mb">Code Stocks</Title>
    <div style={{marginBottom: "20px"}}>
        <Title level={2}>Code Group Balance</Title>
        <CodeGroupBalanceTable className="mb" />
    </div>
    <Title className="CodesStock__filterTitle" level={2}>
      Filters
    </Title>
    <div className={b('buttonsBlock')}>
      <ValidateCode className="mr" selected={selected} afterSuccess={tableProps.fetch} />
      <UpdateCode className="mr" selected={selected} afterSuccess={tableProps.fetch} />
    </div>
    <CodeStockFilters className="mb" filters={tableProps.filters} onFilter={tableProps.onFilter} />
    <CodeStockTable {...tableProps} selected={selected} setSelected={setSelected} />
  </div>;
}

export default CodesStock;
