import { createAction, createReducer } from '@reduxjs/toolkit';
import AccountService from '../Services/AccountService';
import frc from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchAccounts = createAction('accounts/list', (params) => ({
  payload: AccountService.getList(params),
}));

export const updateProxyAccount = createAction('accounts/update-proxy', (params) => ({
  payload: AccountService.changeProxy(params),
}));

export const updateDriverAccount = createAction('accounts/update-driver', (param) => ({
  payload: AccountService.changeDriver(param),
}));

export const updateLoginAccount = createAction('accounts/update-login', (param) => ({
  payload: AccountService.changeLogin(param),
}));

export const updateBalLimitAccount = createAction('accounts/update-balLimit', (param) => ({
  payload: AccountService.changeBalLimit(param),
}));

export const updatePurchLimitAccount = createAction('accounts/update-purchLimit', (param) => ({
  payload: AccountService.changePurchLimit(param),
}));

export const updateCountryAccount = createAction('accounts/update-country', (param) => ({
  payload: AccountService.changeCountry(param),
}));

export const updateEnabledJobsAccount = createAction('accounts/update-enabledJobs', (param) => ({
  payload: AccountService.changeEnabledJobs(param),
}));

export const updateCommentAccount = createAction('accounts/update-comment', (param) => ({
  payload: AccountService.changeComment(param),
}));

export const createAccount = createAction('accounts/create', (data) => ({
  payload: AccountService.createAccount(data),
}));

export const updateAccounts = createAction('accounts/updates', (data) => ({
  payload: AccountService.updateAccounts(data),
}));

export const changePasswordAccount = createAction('accounts/change-password', (data) => ({
  payload: AccountService.changePassword(data),
}));

export const changeTokenAccount = createAction('accounts/change-token', (data) => ({
  payload: AccountService.changeToken(data),
}));

export const changeStatusAccount = createAction('accounts/change-status', (data) => ({
  payload: AccountService.changeStatus(data),
}));

export const fetchAuxAccount = createAction('accounts/fetch-aux', () => ({
  payload: AccountService.fetchAux(),
}));

export const noArgAction = createAction('accounts/no-arg-action', (data) => ({
  payload: AccountService.noArgAction(data),
}));

const initState = {
  list: getDefaultState(),
  updateProxy: getDefaultState(),
  updateLogin: getDefaultState(),
  updateBalLimit: getDefaultState(),
  updatePurchLimit: getDefaultState(),
  updateCountry: getDefaultState(),
  updateEnabledJobs: getDefaultState(),
  updateDriver: getDefaultState(),
  changeStatus: getDefaultState(),
  updateComment: getDefaultState(),
  create: getDefaultState(),
  updateAccounts: getDefaultState(),
  changePassword: getDefaultState(),
  changeToken: getDefaultState(),
  aux: getDefaultState(),
  noArgAction: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAccounts, 'list'),
  ...getDefaultHandler(updateProxyAccount, 'updateProxy'),
  ...getDefaultHandler(updateLoginAccount, 'updateLogin'),
  ...getDefaultHandler(updateBalLimitAccount, 'updateBalLimit'),
  ...getDefaultHandler(updatePurchLimitAccount, 'updatePurchLimit'),
  ...getDefaultHandler(updateCountryAccount, 'updateCountry'),
  ...getDefaultHandler(updateEnabledJobsAccount, 'updateEnabledJobs'),
  ...getDefaultHandler(updateDriverAccount, 'updateDriver'),
  ...getDefaultHandler(updateCommentAccount, 'updateComment'),
  ...getDefaultHandler(createAccount, 'create'),
  ...getDefaultHandler(updateAccounts, 'updateAccounts'),
  ...getDefaultHandler(changePasswordAccount, 'changePassword'),
  ...getDefaultHandler(changeTokenAccount, 'changeToken'),
  ...getDefaultHandler(changeStatusAccount, 'changeStatus'),
  ...getDefaultHandler(fetchAuxAccount, 'aux'),
  ...getDefaultHandler(noArgAction, 'noArgAction'),
});
