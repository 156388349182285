import { createAction, createReducer } from '@reduxjs/toolkit';
import ConfigService from '../Services/ConfigService';
import frc from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchCardsConfig = createAction('config/cards-get', () => ({
  payload: ConfigService.fetchCards(),
}));

export const fetchConfig = createAction('config/fetch', () => ({
  payload: ConfigService.fetchConfigs(),
}));

export const fetchCheckPerDay = createAction('checkPerDay/fetch', () => ({
  payload: ConfigService.fetchCheckPerDay(),
}));

export const updateConfig = createAction('config/update', (data) => ({
  payload: ConfigService.updateConfig(data),
}));

const initState = {
  cards: getDefaultState(),
  updateCard: getDefaultState(),
  createCard: getDefaultState(),
  configs: getDefaultState(),
  updateConfig: getDefaultState(),
  checkPerDay: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchCardsConfig, 'cards'),
  ...getDefaultHandler(fetchConfig, 'configs'),
  ...getDefaultHandler(fetchCheckPerDay, 'checkPerDay'),
  ...getDefaultHandler(updateConfig, 'updateConfig'),
});
