import { Popover } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingBlock from '../LoadingBlock';
import { EditTwoTone } from '@ant-design/icons';
import frc from 'front-end-common';
import {Button} from "@material-ui/core";

const useBoolean = frc.hooks.useBoolean;

const EditableField = ({
                           children,
                           changeBlock,
                           handleSave,
                           initialValue,
                           iconClassName,
                           isLoading,
                           withTitle,
                           title,
                           iconStyle,
                           titlePopover,
                           popoverContentStyle,
                         }) => {
  const { value: showPopover, setFalse: closePopover, setValue: handleVisibleChange } = useBoolean(false);

  const inputRef = useRef(null);
  const [inputValue, handleChange] = useState(initialValue);

  const handleClosePopover = useCallback(() => {
    closePopover();
    handleChange(initialValue);
  }, [initialValue, closePopover]);

  useEffect(() => {
    closePopover();
    handleChange(initialValue);
  }, [initialValue, closePopover, handleSave]);

  useEffect(() => {
    setTimeout(() => {
      if (showPopover && inputRef.current && inputRef.current.input) {
        inputRef.current.input.focus();
      }
    });
  }, [showPopover]);

  const content = (
    <LoadingBlock loading={isLoading} noMinHeight>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {changeBlock({
          value: inputValue,
          onChange: (v) => handleChange(v),
          inputRef,
        })}
        <div className="ml-small flex-ai-center">
          <Button
              size="small"
              variant="contained"
              color="default"
              style={{ marginRight: 5}}
              onClick={handleClosePopover}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            style={inputValue !== initialValue ?{ backgroundColor: "#1976d2"} : {}}
            variant="contained"
            onClick={() => handleSave(inputValue)}
            disabled={inputValue === initialValue}
            className="ml-small"
          >
            Save
          </Button>
        </div>
      </div>
    </LoadingBlock>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={showPopover}
      onVisibleChange={handleVisibleChange}
      title={withTitle ? title || 'Edit' : null}
    >
      <span style={{ cursor: 'pointer', position: 'relative', ...popoverContentStyle }}>
        <Popover content={titlePopover || 'Edit'}>
          {children}
          <EditTwoTone
            className={iconClassName}
            style={{
              position: 'absolute',
              top: '50%',
              left: 'calc(100% + 1px)',
              transform: 'translateY(-50%)',
              ...iconStyle,
            }}
          />
        </Popover>
      </span>
    </Popover>
  );
};

EditableField.propTypes = {
  children: PropTypes.node,
  changeBlock: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  iconClassName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  withTitle: PropTypes.bool,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  iconStyle: PropTypes.object,
  titlePopover: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  popoverContentStyle: PropTypes.object,
};

EditableField.defaultProps = {
  initialValue: null,
  iconClassName: '',
  withTitle: true,
  title: '',
  iconStyle: {},
  titlePopover: '',
  popoverContentStyle: {},
};

export default React.memo(EditableField);
