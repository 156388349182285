import React, { useCallback } from 'react';
import { Drawer, Form, Input, message } from 'antd';
import ErrorBlock from '../../../Components/ErrorBlock';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeTokenAccount } from '../../../Reducers/accounts';
import frc from 'front-end-common';
import {Button, Link} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

const useBoolean = frc.hooks.useBoolean;

function ChangeToken({ className, accountId, token }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { error, isLoading } = useSelector(state => state.accounts.changeToken, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(({ token }) => {
    dispatch(changeTokenAccount({ accountIds: [accountId], authToken: token })).then(() => {
      message.success('Token has been updated!');
      close();
    });
  }, [dispatch, accountId, close]);

  return <>
    <Link className={className} style={{ textDecoration: 'none' }} color="primary" size="small" onClick={open}>Set Token</Link>
    <Drawer onClose={close}
            visible={isOpen}
            title="Change Token"
            placement="right"
            width={600}
    >
      {error && <ErrorBlock message={error.message} />}
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            { required: true, message: 'Please input new token.' },
          ]}
          label="New Token"
          name="token"
          initialValue={token}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#1976d2"}}
              startIcon={isLoading && <LoadingOutlined />}>
            Save Token
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default ChangeToken;
