import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import b_ from 'b_';
import { Checkbox, Drawer, Form, Input, message, Popconfirm, Popover} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import EditFilled from '@ant-design/icons/lib/icons/EditFilled';
import {validateCodeStock} from '../../../Reducers/codeStock';
import './styles.scss'
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import {Button} from "@material-ui/core";

const b = b_.lock('UpdateCode');
const useBoolean = frc.hooks.useBoolean;
const soldNotActivated = 'Sold Not Activated'

function ValidateCode({className, afterSuccess, selected}) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const {isLoading, error} = useSelector(state => state.codeStock.update.isLoading, shallowEqual);
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {value: showPopover, setFalse: closePopover, setValue: handleVisibleChange} = useBoolean(false);
  const [confirmValue, setConfirmValue] = useState('');
  const onChangeConfirm = useCallback((e) => setConfirmValue(e.target.value), [setConfirmValue]);
  const disabled = useMemo(() => confirmValue.toLocaleLowerCase() !== 'validate', [confirmValue]);

  useEffect(() => {
    setConfirmValue('');
  }, [showPopover]);

  const handleSuccess = useCallback((formFields) => {
    const data = selected.map(v => ({ platform: v.platform, code: v.code}));

    dispatch(validateCodeStock(data)).then(() => {
      close();
      closePopover();
      afterSuccess();
      message.success('Codes has been validated!');
      form.resetFields();
    });
  }, [close, afterSuccess, dispatch, selected, form]);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  return (
    <>
      <Popover
        forceRender
        content={
          <Form layout="vertical" form={form}>
            <Form.Item className="mb-small" value={confirmValue} onChange={onChangeConfirm}/>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSuccess}
                style={!disabled ? { backgroundColor: "#1976d2"} : {} }
                startIcon={isLoading && <LoadingOutlined/> }
                disabled={disabled}
                size="small"
            >
              Confirm
            </Button>
          </ Form>
        }
        onVisibleChange={handleVisibleChange}
        visible={showPopover}
        trigger="click"
        title="For confirm action please type 'validate':"
      >
        <Button
                disabled={selected.length === 0 || selected.filter(v => v.status !== soldNotActivated).length !== 0}
                color="primary"
                variant="contained"
                className={className}
                icon={<EditFilled/>}
                style={selected.filter(v => v.status !== soldNotActivated).length !== 0 ? {
                    backgroundColor: "#1976d2",
                    width: '200px',
                    overflow: 'hidden'} :{} }
                onClick={open}
        >
          Validate {selected.filter(v => v.status === soldNotActivated).length} codes
        </Button>
      </Popover>
    </>
  );
}

export default ValidateCode;
