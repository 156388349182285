import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function PlatformSelector({ className, value, onChange, defaultValue, placeholder, disabled, size }) {
  return (
    <Select defaultValue={defaultValue}
            placeholder={placeholder}
            className={className}
            size={size}
            value={value}
            onChange={onChange}
            disabled={disabled}
            allowClear
    >
      <Select.Option value="R-Airbnb">R-Airbnb</Select.Option>
      <Select.Option value="R-PSN">R-PSN</Select.Option>
      <Select.Option value="R-Amazon">R-Amazon</Select.Option>
      <Select.Option value="R-XBox">R-XBox</Select.Option>
      <Select.Option value="R-XBox-Pass">R-XBox-Pass</Select.Option>
      <Select.Option value="R-Steam">R-Steam</Select.Option>
      <Select.Option value="R-Nintendo">R-Nintendo</Select.Option>
      <Select.Option value="R-Google">R-Google</Select.Option>
      <Select.Option value="R-Blizzard">R-Blizzard</Select.Option>
      <Select.Option value="R-Walmart">R-Walmart</Select.Option>
      <Select.Option value="R-Apple">R-Apple</Select.Option>
      <Select.Option value="R-Ebay">R-Ebay</Select.Option>
      <Select.Option value="R-Netflix">R-Netflix</Select.Option>
      <Select.Option value="R-IKEA">R-IKEA</Select.Option>
      <Select.Option value="R-Spotify">R-Spotify</Select.Option>
      <Select.Option value="R-Zalando">R-Zalando</Select.Option>
      <Select.Option value="R-Macys">R-Macys</Select.Option>
      <Select.Option value="R-Gamestop">R-Gamestop</Select.Option>
      <Select.Option value="R-Gap">R-Gap</Select.Option>
      <Select.Option value="R-H&M">R-H&M</Select.Option>
      <Select.Option value="R-Nike">R-Nike</Select.Option>
      <Select.Option value="R-Starbucks">R-Starbucks</Select.Option>
      <Select.Option value="R-OldNavy">R-OldNavy</Select.Option>
      <Select.Option value="R-KarmaKoin">R-KarmaKoin</Select.Option>
      <Select.Option value="R-Cabelas">R-Cabelas</Select.Option>
      <Select.Option value="R-Hulu">R-Hulu</Select.Option>
      <Select.Option value="R-BestBuy">R-BestBuy</Select.Option>
      <Select.Option value="R-BarnesAndNoble">R-BarnesAndNoble</Select.Option>
      <Select.Option value="R-MYTOYS">R-MYTOYS</Select.Option>
      <Select.Option value="R-Hotels">R-Hotels</Select.Option>
    </Select>
  );
}

PlatformSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

PlatformSelector.defaultProps = {
  disabled: false,
};

export default PlatformSelector;
