import React, { useCallback, useEffect } from 'react';
import b_ from 'b_';
import { List } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { fetchConfig } from '../../../Reducers/config';
import ConfigItem from './ConfigItem';
import './styles.scss';

const b = b_.lock('ConfigList');

function ConfigList() {
  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const { configs, updateConfig } = useSelector(state => state.config, shallowEqual);

  return <>
    {configs.error && <ErrorBlock className="mb" message={configs.error.message} />}
    <List loading={configs.isLoading || updateConfig.isLoading}
          dataSource={configs.payload  || []}
          bordered
          className={b('list')}
          renderItem={item => (
            <List.Item className={b('listItem')}>
              <ConfigItem fetch={fetch} item={item} />
            </List.Item>
          )}
    />
  </>;
}

export default ConfigList;
