import React, { useEffect, useState } from 'react';
import b_ from 'b_';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import ResizeTable from "../../../Components/ResizebleTitle";
import { fetchConfig } from "../../../Reducers/config";

const b = b_.lock('StateTable');

function StateTable({
    list,
    pagination,
    onChange,
    isLoading,
    onFilter,
    filters,
    sorter,
}) {
    const dispatch = useDispatch();

    const loadings = useSelector(state => ({
        proxy: state.accounts.updateProxy.isLoading,
        configs: state.config.configs.isLoading,
        driver: state.accounts.updateDriver.isLoading,
        comment: state.accounts.updateComment.isLoading,
        status: state.accounts.changeStatus.isLoading,
        login: state.accounts.updateLogin.isLoading,
        country: state.accounts.updateCountry.isLoading,
    }), shallowEqual);

    const { accountCodeFilters } = useSelector(
        (state) => {
            if (!state.config.configs.payload) return { accountCodeFilters: null };

            try {
                let accountCodeFilters = state.config.configs.payload.find(({ name }) => name === 'resultCodeFilter');
                accountCodeFilters = accountCodeFilters ? JSON.parse(accountCodeFilters.value) : null;

                return { accountCodeFilters };
            } catch (e) {
                return { accountCodeFilters: null };
            }
        },
        shallowEqual
    );

    useEffect(() => {
        !accountCodeFilters && dispatch(fetchConfig())
    }, [accountCodeFilters])

    const GetColumns = () => {
        return [
            {
                dataIndex: "platform",
                title: "Platform",
                align: "center",
                width: 130,
                filters: [
                    { text: 'R-PSN', value: 'R-PSN' },
                    { text: 'R-Amazon', value: 'R-Amazon' },
                    { text: 'R-XBox', value: 'R-XBox' },
                    { text: 'R-XBox-Pass', value: 'R-XBox-Pass' },
                    { text: 'R-Steam', value: 'R-Steam' },
                    { text: 'R-Nintendo', value: 'R-Nintendo' },
                    { text: 'R-Google', value: 'R-Google' },
                    { text: 'R-Blizzard', value: 'R-Blizzard' },
                    { text: 'R-Walmart', value: 'R-Walmart' },
                    { text: 'R-Apple', value: 'R-Apple' },
                    { text: 'R-Ebay', value: 'R-Ebay' },
                    { text: 'R-Netflix', value: 'R-Netflix' },
                    { text: 'R-IKEA', value: 'R-IKEA' },
                    { text: 'R-Spotify', value: 'R-Spotify' },
                    { text: 'R-Zalando', value: 'R-Zalando' },
                    { text: 'R-Macys', value: 'R-Macys' },
                    { text: 'R-Gamestop', value: 'R-Gamestop' },
                    { text: 'R-Gap', value: 'R-Gap' },
                    { text: 'R-H&M', value: 'R-H&M' },
                    { text: 'R-Nike', value: 'R-Nike' },
                    { text: 'R-Starbucks', value: 'R-Starbucks' },
                    { text: 'R-OldNavy', value: 'R-OldNavy' },
                    { text: 'R-KarmaKoin', value: 'R-KarmaKoin' },
                    { text: 'R-Cabelas', value: 'R-Cabelas' },
                    { text: 'R-Hulu', value: 'R-Hulu' },
                    { text: 'R-BestBuy', value: 'R-BestBuy' },
                    { text: 'R-BarnesAndNoble', value: 'R-BarnesAndNoble' },
                    { text: 'R-MYTOYS', value: 'R-MYTOYS' },
                    {text: 'R-Hotels', value: 'R-Hotels'},
                ],
                filterMultiple: false,
                filteredValue: filters['platform'] || null,
                sorter,
                sortOrder: sorter.field === 'platform' ? sorter.order : null,
                render: platform => <Link to={`/?limit=50&offset=0&filters[platform][]=${platform}`}>{platform}</Link>,
            },
            {
                dataIndex: "country",
                title: "Country",
                align: "center",
                width: 115,
                sorter,
                sortOrder: sorter.field === 'country' ? sorter.order : null,
                render: (country, record) => <Link to={`/?limit=50&offset=0&filters[platform][]=${record.platform}&filters[country][]=${country}`}>{country}</Link>,
            },
            {
                dataIndex: "countAccounts",
                title: "All",
                align: "center",
                width: 100,
            },
            {
                dataIndex: "enabledAccounts",
                title: "Enabled",
                align: "center",
                width: 100,
                sorter,
                sortOrder: sorter.field === 'status' ? sorter.order : null,
                render: (enabled, record) => <Link to={`/?limit=50&offset=0&filters[platform][]=${record.platform}&filters[country][]=${record.country}&filters[status][]=Enabled`}>{enabled}</Link>,
            },
            {
                dataIndex: "disabledAccounts",
                title: "Disabled",
                align: "center",
                width: 110,
                sorter,
                sortOrder: sorter.field === 'status' ? sorter.order : null,
                render: (disabled, record) => <Link to={`/?limit=50&offset=0&filters[platform][]=${record.platform}&filters[country][]=${record.country}&filters[status][]=Disabled`}>{disabled}</Link>,
            },
            {
                dataIndex: "invalidAccounts",
                title: "Invalid",
                align: "center",
                width: 100,
                sorter,
                sortOrder: sorter.field === 'status' ? sorter.order : null,
                render: (invalid, record) => <Link to={`/?limit=50&offset=0&filters[platform][]=${record.platform}&filters[country][]=${record.country}&filters[status][]=Invalid`}>{invalid}</Link>,
            },
            {
                dataIndex: "statusInvalidAccounts",
                title: "Invalid status",
                align: "left",
                width: 160,
                render: (statuses, record) => (<div>
                    {statuses.map(status =>
                        <Link to={`/?limit=50&offset=0&filters[platform][]=${record.platform}&filters[country][]=${record.country}&filters[status][]=Invalid&filters[resultCode][]=${status.status}`} className="mb-small block" >{status.status}: {status.count}</Link>
                    )}
                </div>),
            },
        ]
    }

    const [columns, setColumns] = useState(GetColumns());

    useEffect(() => {
        setColumns(GetColumns())
    }, [list,
        isLoading,
        filters
    ])

    const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

    return <ResizeTable
        bordered
        className={b()}
        dataSource={list}
        scroll={{ y: "calc(100vh - 125px)", x: "max-content" }}
        size="middle"
        onChange={onChange}
        pagination={pagination}
        loading={loader}
        onFilter={onFilter}
        columns={columns}
        rowClassName={() => b('lock')}
        rowKey="id"
    />
}

export default StateTable;
