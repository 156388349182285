import qs from 'qs';
import frc from 'front-end-common';
import TokenService from './TokenService';
import {isLocal} from "../Utils/domainUtils";
const {
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_CLIENT_ID_LOCAL: CLIENT_ID_LOCAL,
  REACT_APP_AUTH_URL: AUTH_URL,
  REACT_APP_AUTH_URL_LOCAL: AUTH_URL_LOCAL,
} = process.env;

const cognitoCredType = isLocal() ? {CLIENT_ID, AUTH_URL,} : {CLIENT_ID: CLIENT_ID_LOCAL, AUTH_URL: AUTH_URL_LOCAL}

export function prepareQueryString(params) {
  const updParams = Object.keys(params)
    .filter(key => params[key] !== null)
    .reduce((acc, cur) => ({ ...acc, [cur]: params[cur] }), {});

  return qs.stringify(updParams, { encode: false, arrayFormat: 'brackets' });
}

export function parseQueryString(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

const API_PREFIX = process.env.REACT_APP_API_PREFIX || '/api/bo';

export default new frc.services.ApiService(API_PREFIX, TokenService, cognitoCredType);
