import React, {useEffect, useState} from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import CodesTable from "./CodesTable";
import {fetchCodesId} from "../../Reducers/codes";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import useTable from "../../Utils/useTable";
import './styles.scss'
import { Descriptions } from 'antd';

const b = b_.lock('Code');

function Codes () {

    const params = useParams()
    const dispatch = useDispatch();

    const {
        code,
        states,
        isLoading,
        sort
    } = useTable(state =>  state.codes.codes, fetchCodesId);
    useEffect(() => {
        dispatch(fetchCodesId(params));
    }, [dispatch]);

    const [selectedRow, setSelectedRow] = useState([]);

    const massKeyCode = Object.keys(code)
    return <div className={b()}>
        <Title className="mb">
            Code
        </Title>
        <Descriptions title="Code Info" column={3} className={b("description")}>
            {
                massKeyCode.map(e => (<Descriptions.Item span={2} className={b("row")} style={{width: 150}} label={e}>{code[e] || `" "`}</Descriptions.Item>))
            }
        </Descriptions>
        <CodesTable
            sorter={sort}
            selected={selectedRow}
            setSelected={setSelectedRow}
            isLoading={isLoading}
            list={states}/>
    </div>;
}

export default Codes;
