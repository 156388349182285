import React from "react";
import { useRoutes } from "react-router-dom";
import RedirectUserToMainPage from '../Components/RedirectUserToMainPage';
import RedirectGuestToSignInPage from '../Components/RedirectGuestToSignInPage';
import Accounts from '../Pages/Accounts';
import State from '../Pages/State';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import HistoryOutlined from '@ant-design/icons/lib/icons/HistoryOutlined';
import RedirectPage from '../Pages/RedirectPage';
import CheckCodesJobs from '../Pages/CheckCodesJob';
import CheckSquareOutlined from '@ant-design/icons/lib/icons/CheckSquareOutlined';
import OtherJobs from '../Pages/OtherJob';
import BarsOutlined from '@ant-design/icons/lib/icons/BarsOutlined';
import Account from '../Pages/Account';
import CodesStock from '../Pages/CodesStock';
import StockOutlined from '@ant-design/icons/lib/icons/StockOutlined';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import Config from '../Pages/Config';
import Driver from '../Pages/Driver';
import CloudSyncOutlined from '@ant-design/icons/lib/icons/CloudSyncOutlined';
import Codes from "../Pages/Codes/index";

function Routes() {
  const element = useRoutes([
    {
      path: '/sign-in',
      exact: true,
      element: <RedirectUserToMainPage />,
    },
    {
      path: '/redirect',
      exact: true,
      element: <RedirectPage />,
    },
    {
      path: '/',
      element: <RedirectGuestToSignInPage />,
      children: [
        {
          path: '/',
          exact: true,
          element: <Accounts />,
        }, {
          path: '/state',
          exact: true,
          element: <State />,
        }, {
          path: '/check-codes-jobs',
          exact: true,
          element: <CheckCodesJobs />,
        }, {
          path: '/other-jobs',
          exact: true,
          element: <OtherJobs />,
        }, {
          path: '/account/:id',
          exact: true,
          element: <Account />,
        }, {
          path: '/code-stock',
          exact: true,
          element: <CodesStock />,
        }, {
          path: '/config',
          exact: true,
          element: <Config />,
        }, {
          path: '/driver',
          exact: true,
          element: <Driver />,
        },{
          path: '/codes/:id',
          exact: true,
          element: <Codes />,
        },
      ],
    },
  ]);
  return element;
}

export const links = [
  {
    href: '/',
    icon: <UserOutlined />,
    title: 'Accounts',
  }, {
    href: '/state',
    icon: <HistoryOutlined />,
    title: 'Accounts state',
  }, {
    href: '/check-codes-jobs',
    icon: <CheckSquareOutlined />,
    title: 'Check codes jobs',
  }, {
    href: '/other-jobs',
    icon: <BarsOutlined />,
    title: 'Other jobs',
  }, {
    href: '/code-stock',
    icon: <StockOutlined />,
    title: 'Code Stock',
  }, {
    href: '/config',
    icon: <SettingOutlined />,
    title: 'Config',
  }, {
    href: '/driver',
    icon: <CloudSyncOutlined />,
    title: 'Driver',
  },
];

export default Routes;
