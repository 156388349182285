import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function JobStatusPicker({ className, value, onChange }) {

  return <Select value={value} onChange={onChange} className={className} allowClear>
    <Select.Option value="New">New</Select.Option>
    <Select.Option value="Done">Done</Select.Option>
    <Select.Option value="Error">Error</Select.Option>
  </Select>;
}

JobStatusPicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOf(['New', 'Error', 'Done', null]).isRequired,
  onChange: PropTypes.func,
};

JobStatusPicker.defaultProps = {
  className: '',
};

export default JobStatusPicker;
