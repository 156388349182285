import React, { useCallback } from 'react';
import b_ from 'b_';
import { Input, message, Select, Table } from 'antd';
import EditableField from '../../../Components/EditableField';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import TextArea from 'antd/lib/input/TextArea';
import ChangePassword from '../../Accounts/ChangePassword';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  changeStatusAccount,
  updateCommentAccount,
  updateDriverAccount,
  updateProxyAccount,
  updateCountryAccount,
  updateLoginAccount,
} from '../../../Reducers/account';
import CountrySelector from '../../Accounts/Selectors/CountrySelector';
import ChangeToken from '../../Accounts/ChangeToken';
import './styles.scss';
import Expanded from '../../Accounts/AccountsTable/Expanded';
import ResetProfile from "../../Accounts/AccountResetProfileButton";
import {removeUserDataDir} from "../../../Reducers/driver";
import DefaultProfile from "../../Accounts/AccountUseDefaultProfileButton";
import {defaultUserDataDir} from "../../../Reducers/driver";

const b = b_.lock('AccountTable');

function AccountTable({ data, isLoading, afterUpdate, className }) {
  const dispatch = useDispatch();

  const loadings = useSelector(state => ({
    proxy: state.account.updateProxy.isLoading,
    driver: state.account.updateDriver.isLoading,
    comment: state.account.updateComment.isLoading,
    status: state.account.changeStatus.isLoading,
    login: state.account.updateLogin.isLoading,
    country: state.account.updateCountry.isLoading,
  }), shallowEqual);

  const updateCountry = useCallback((id, country) => {
    dispatch(updateCountryAccount({ id, country })).then(() => {
      message.success('Country has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update country.');
    });
  }, [dispatch, afterUpdate]);

  const updateLogin = useCallback((id, login) => {
    dispatch(updateLoginAccount({ id, login })).then(() => {
      message.success('Login has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update login');
    });
  }, [dispatch, afterUpdate]);

  const updateProxy = useCallback((id, proxy) => {
    dispatch(updateProxyAccount({ id, proxy })).then(() => {
      message.success('Proxy has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update proxy');
    });
  }, [dispatch, afterUpdate]);

  const updateDriver = useCallback((id, driver) => {
    dispatch(updateDriverAccount({ id, driver })).then(() => {
      message.success('Driver has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update driver');
    });
  }, [dispatch, afterUpdate]);

  const updateComment = useCallback((id, comment) => {
    dispatch(updateCommentAccount({ id, comment })).then(() => {
      message.success('Comment has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update comment');
    });
  }, [dispatch, afterUpdate]);

  const updateStatus = useCallback((id, status) => {
    dispatch(changeStatusAccount({ id, action: status.toLowerCase() })).then(() => {
      message.success('Status has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update status');
    });
  }, [dispatch, afterUpdate]);

  const removeUserData = useCallback((id) => {
      dispatch(removeUserDataDir(id)).then(() => {
          message.success('Data user Chrome remove!');
          afterUpdate();
      }).catch(error => {
          console.log(error);
          message.error('Can\'t remove data Chrome user');
      })
  }, [dispatch]);

    const defaultUserData = useCallback((id) => {
        dispatch(defaultUserDataDir(id)).then(() => {
            message.success('Default User Chrome Data Chrome copied!');
            afterUpdate();
        }).catch(error => {
            console.log(error);
            message.error('Can\'t use default user chrome data ');
        })
    }, [dispatch]);

  return <Table bordered
                className={`${b()} ${className}`}
                loading={isLoading}
                scroll={{ x: 'max-content' }}
                dataSource={data}
                pagination={false}
                rowClassName={() => b('lock')}
                rowKey="id"
                expandable={{
                  expandedRowRender: record => <Expanded record={record} />,
                  rowExpandable: record => !!record.resultDetails,
                }}
  >
    <Table.Column dataIndex="id"
                  title="ID"
    />

    <Table.Column dataIndex="platform"
                  title="Platform"
    />
    <Table.Column dataIndex="login"
                  title="Login"
                  render={(login, rec) => (
                    <EditableField handleSave={(newLogin) => updateLogin(rec.id, newLogin)}
                                   title="Update Login"
                                   withTitle
                                   titlePopover="Edit Login"
                                   isLoading={loadings.login || isLoading}
                                   iconClassName={b('edit')}
                                   initialValue={login}
                                   changeBlock={({ onChange, ...props }) => (
                                     <Input {...props}
                                            size="small"
                                            onChange={e => onChange(e.target.value)} />
                                   )}>
                      {login}
                    </EditableField>
                  )}
    />
    <Table.Column dataIndex="status"
                  title="Status"
                  render={(status, rec) => (
                    <>
                      <PrintAccountState 
                        status={status}
                        field={"status"}
                        rec={rec}
                        handleSave={(newStatus) => updateStatus(rec.id, newStatus)}/>
                      
                      <EditableField handleSave={(newStatus) => updateStatus(rec.id, newStatus)}
                                    title="Update Status"
                                    withTitle
                                    titlePopover="Edit Status"
                                    isLoading={loadings.status || isLoading}
                                    iconClassName={b('edit')}
                                    initialValue={status}
                                    changeBlock={(props) => (
                                      <Select {...props} size="small">
                                        <Select.Option value="Disable">Disable</Select.Option>
                                        <Select.Option value="Enable">Enable</Select.Option>
                                      </Select>
                                    )}>
                      </EditableField>
                    </>
                  )}
    />
    <Table.Column dataIndex="country"
                  title="Country"
                  render={(value, rec) =>
                    <EditableField handleSave={(newValue) => updateCountry(rec.id, newValue)}
                                   title="Update Country"
                                   titlePopover="Edit Country"
                                   iconClassName={b('edit')}
                                   withTitle
                                   isLoading={loadings.country || isLoading}
                                   initialValue={value}
                                   changeBlock={(props) => (
                                     <CountrySelector {...props}
                                                      size="small"
                                                      className="w100"
                                     />
                                   )}>
                      {value}
                    </EditableField>
                  }
    />
    <Table.Column dataIndex="proxy"
                  title="Proxy"
                  render={(value, rec) =>
                    <EditableField handleSave={(newProxy) => updateProxy(rec.id, newProxy)}
                                   title="Update Proxy"
                                   titlePopover="Edit Proxy"
                                   iconClassName={b('edit')}
                                   withTitle
                                   isLoading={loadings.proxy || isLoading}
                                   initialValue={value}
                                   changeBlock={({ onChange, ...props }) => (
                                     <Input {...props}
                                            size="small"
                                            onChange={e => onChange(e.target.value)} />
                                   )}>
                      {value || <i>none</i>}
                    </EditableField>
                   }
    />
    <Table.Column dataIndex="driver"
                  title="Driver"
                  align="right"
                  render={(value, rec) =>
                    <EditableField handleSave={(newDriver) => updateDriver(rec.id, newDriver)}
                                   title="Update Driver"
                                   titlePopover="Edit Driver"
                                   isLoading={loadings.driver || isLoading}
                                   iconClassName={b('edit')}
                                   withTitle
                                   initialValue={value}
                                   changeBlock={({ onChange, ...props }) => (
                                     <Input {...props}
                                            size="small"
                                            type="number"
                                            onChange={e => onChange(e.target.value)} />
                                   )}>
                      {value}
                    </EditableField>
                  }
    />
    <Table.Column dataIndex="comment"
                  title="Comment"
                  render={(value, rec) =>
                    <EditableField handleSave={(newComment) => updateComment(rec.id, newComment)}
                                   title="Update Comment"
                                   titlePopover="Edit Comment"
                                   isLoading={loadings.comment || isLoading}
                                   iconClassName={b('edit')}
                                   withTitle
                                   initialValue={value}
                                   changeBlock={({ onChange, ...props }) => (
                                     <TextArea {...props}
                                               size="small"
                                               onChange={e => onChange(e.target.value)} />
                                   )}>
                      {value}
                    </EditableField>
                  }
    />
    <Table.Column dataIndex="resultCode"
                  title="Result Code"
    />
    <Table.Column dataIndex="id"
                  title="Actions"
                  render={(id, rec) => <div className={b('action')}>
                    <ChangePassword className="mr-small" accountId={id} />
                    <ChangeToken token={rec.authToken} accountId={id} />
                    <ResetProfile accountId={id} removeUserData={removeUserData}/>
                    <DefaultProfile accountId={id} defaultUserData={defaultUserData}/>
                  </div>}
    />
  </Table>;
}

export default AccountTable;
