import React from 'react';
import b_ from 'b_';
import { Layout as AntLayout } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import './styles.scss';

const Content = AntLayout.Content;

const b = b_.lock('Layout');

function Layout() {
  return <AntLayout className={b()}>
    <AntLayout>
      <Header />
      <Content className={b('content')}>
        <Outlet />
      </Content>
    </AntLayout>
  </AntLayout>;
}

export default Layout;
