import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { serverDateToDate, serverDateToDateWithTimezone } from '../../../Utils/dates';

function PrintDate({ className, date, withTime }) {
  const prepareFunc = useCallback(
    withTime ? serverDateToDateWithTimezone : serverDateToDate,
    [withTime],
  );
  return (<span className={className}>
    {prepareFunc(date)}
  </span>);
}

PrintDate.propTypes = {
  className: PropTypes.string,
  date: PropTypes.number.isRequired,
  withTime: PropTypes.bool,
};

PrintDate.defaultProps = {
  className: '',
  withTime: false,
};

export default PrintDate;
