import ApiService from './ApiService';

export default {
  fetchCards() {
    return ApiService.get('/cards');
  },
  fetchConfigs() {
    return ApiService.get('/configs');
  },
  fetchCheckPerDay() {
    return ApiService.get('/config?nameConfig=limitCheckPerDay');
  },
  updateConfig(data) {
    return ApiService.put('/config', data);
  }
};
