import { createAction, createReducer } from '@reduxjs/toolkit';
import CheckCodesJobService from '../Services/CheckCodesJobService';
import frc from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchCheckCodesJobs = createAction('checkCodesJobs/list', (params) => ({
  payload: CheckCodesJobService.getList(params),
}));

export const cancelCheckCodesJobs = createAction('checkCodesJobs/cancel', (ids) => ({
  payload: CheckCodesJobService.cancel(ids),
}));

export const resultCodesCheckCodesJobs = createAction('checkCodesJobs/result-codes', (ids) => ({
  payload: CheckCodesJobService.resultCodes(ids),
}));

export const zalandoCheckCodes = createAction('zalando/check-codes', (data) => ({
  payload: CheckCodesJobService.zalandoCheckCodes(data),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
  resultCodes: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchCheckCodesJobs, 'list'),
  ...getDefaultHandler(cancelCheckCodesJobs, 'cancel'),
  ...getDefaultHandler(resultCodesCheckCodesJobs, 'resultCodes'),
});
